.review-container {
    background-color: #121212;
    color: white;
    height:calc(63dvh + 50px);
    padding: 20px;
}


.review-container .tabs {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
    background-color: #27272A;
    padding: 10px;
    border-radius: 10px;
    width: fit-content;
}

.review-container .tab {
    /* background: #222; */
    padding: 10px 20px;
    min-width: 80px;
    border-radius: 6px;
    cursor: pointer;
    font-size: 14px;
    text-align: center;
    color: #aaa;
    transition: 0.3s;
    font-weight: bold;
}

.review-container .tab.active {
    background: #09090B;
    color: white;
    font-weight: bold;
    
}

.review-container .review-card {
    background: #000;
    border: 2px solid #a1a1a1;
    padding: 14px;
    border-radius: 8px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.review-container .review-icon {
    font-size: 20px;
    margin: 10px;
    background-color: #353535;
    padding: 10px;
    border-radius: 10px;
}

.review-container .review-each-card {
    display: flex;
    gap: 25px;
    padding-top: 10px;
    
}

.review-container .review-details {
    flex-grow: 1;
}

.review-container .review-title {
    font-weight: bold;
    font-size: 15px;
}

.review-container .review-meta {
    font-size: 12px;
    color: #aaa;
}

.review-container .status-btn {
    display: flex;
    padding: 5px 10px;
    border-radius: 6px;
    font-size: 12px;
    font-weight: bold;
    gap: 8px;
}

.review-container .button-container {
    display: flex;
    gap: 10px;
}

.review-container .view-container {
    display: flex;
    align-items: center;
    justify-items: center;
    gap: 10px;
    padding-left: 15px;
    padding-right: 15px;
    background: #1e1e1e;
    color: #C4C4C4;
    border: 2px solid #a1a1a1;
    border-radius: 12px;
    cursor: pointer;
}

.review-container .button-text {
    font-size: 12px;
    font-weight: bold;
}

.review-container .status-review {
    background: #d9d9d9;
    color: #000;
}

.review-container .status-approved {
    background: #28a745;
    color: white;
}

.review-container .status-rejected {
    background: #EA6045;
    color: white;
}

.review-container .left-bottom-container {
    display: flex;
    gap: 10px;
    padding-top: 20px;
    padding-left: 5px;
}