:root {
  --bg-dark: #131313;
  --text-light: #fff;
  --text-muted: #aaa;
  --primary: #1f1f1f;
  --secondary: #333;
  --button-bg: #555;
}

.wrapper {
  margin: 19px 30px 0 17px;
  width: 100%;
  max-height: 97vh;
  /* or calc(100vh - 4rem) etc. */
  overflow-y: auto;
  /* enable scrolling within the container */
  /* Hide scrollbar for Firefox */
  scrollbar-width: none;
  /* Hide scrollbar for IE, Edge */
  -ms-overflow-style: none;
  padding: 30px 15px !important;
}

/* Hide scrollbar for Chrome, Safari, and Opera */
.wrapper::-webkit-scrollbar {
  display: none;
}

/* body {
  font-family: "Arial", sans-serif;
  background-color: var(--bg-dark);
  color: var(--text-light);
  margin: 0;
  padding: 0;
} */

.wrapper h2 {
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
}




.wrapper .plan {
    width: 173px;
    padding: 9px;
    background: #27272A;
    font-weight: 700;
    font-size: 15px;
    line-height: 22.5px;
    color: #fff;
}
.card {
  background: #1E1E1E;
  padding: 12px;
  border-radius: 10px;
  border: 0.5px solid #A1A1A1;
}

.wrapper .dark {
  background: #09090B;
  padding: 22px;
}

.wrapper .button {
  background: #fff;
  padding: 6px 18px;
  border: none;
  border-radius: 7px;
  font-weight: 700;
  font-size: 14px;
  height: 31px;
  line-height: 15px;
  color: #1e1e1e;
  cursor: pointer;
  width: fit-content;
}

.wrapper .invoice {
  background: #27272A;
  padding: 30px 22px;
  border-radius: 10px;
}
.wrapper .flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.wrapper .inline-block {
  display: inline-block;
  margin: 0 50px;
}

.wrapper .status {
  background: #27272A;
  padding: 6px 15px;
  border-radius: 7px;
  border: none;
  color: #fff;
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
    height: fit-content;
    width: 90px;
}


.wrapper p, h5 {
  margin: 0;
}


.wrapper .id {
font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  margin-bottom: 11px;
}

.wrapper .price {
  font-weight: 700;
  font-size: 15px;
  line-height: 22.5px;
}

.wrapper .due {
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #A1A1A1;
}

.wrapper .border-b {
  border-bottom: 0.5px solid #FFFFFF;
}

.wrapper .muted {
  color: #979A9C;
}

.wrapper .grid {
  display: grid;
  gap: 10px;
  margin: 54px 0 29px;
}

.wrapper .grid-2 {
  gap: 32px;
}

@media (min-width: 768px) {
  .wrapper .grid-2 {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 1024px) {
  .wrapper .grid-3 {
    grid-template-columns: repeat(3, 1fr);
  }
}

.wrapper .box {
  background: #fff;
  padding: 16px;
  border-radius: 12px;
  display: flex;
  gap: 10px;
  align-items: center;
  margin-left: 5px;
  width: 235px;
}

.wrapper .info {
  display: flex;
  gap: 5px;
  flex-direction: column;
}

.wrapper .info p {
  font-size: 12px;
  line-height: 16.5px;
  color: #5A5A5A;
}

.wrapper .info h6 {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #121212;
}