/* Full-screen overlay with blur effect */
.notification-overlay {
    position: fixed;
    inset: 0;
    display: flex;
    background: rgba(0, 0, 0, 0.2);
    justify-content: flex-end; /* Align to the right */
    align-items: center; /* Stick to the top */
    padding: 70px 20px 0px 10px;
    z-index: 1050;
}
  

/* Notification Modal Box */
.notification-modal {
    width: 77%; /* Adjust width as per design */
    min-height:85vh;
    max-height: 85vh; /* Prevents it from taking the full height */
    background-color: #1E1E1E;
    border: 1px solid #A1A1A1;
    border-radius: 8px;
    border-radius: 12px;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
    padding: 15px;
    overflow-y: auto;
    display: flex;
    flex-direction: column; 
    scrollbar-width: thin;
}

/* Modal Header */
.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* border-bottom: 1px solid #ddd; */
    padding: 0px 12px 10px 12px;
}

.modal-header .modal-title {
    font-size: 0.7rem;
    color:#FFFFFF;
    font-weight:700;
}

.modal-header .btn-close {
    color: #FFFFFF !important;
    border: none;
    font-size: 18px;
    cursor: pointer;
}

/* Account Overview Section */
.account_overview_container{
    display: flex;
    flex-direction: column;
    background-color: #09090B;
    padding: 25px 20px;
    margin: 20px 0px 35px 0px;
    border: 0.5px solid #A1A1A1;
    border-radius: 10px;
}

.account_overview_header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.account_overview_header h5{
    color:#FFFFFF;
    font-weight: 300;
    font-size: 0.9rem;
    margin: 0;

}


.account_overview_header button{
    color:#1E1E1E;
    font-weight: 700;
    padding: 6px 35px;
    font-size:0.65rem;
    border-radius: 7px;
    background-color: #FFFFFF;
}




.account-overview {
    display: flex;
    /* grid-template-columns: repeat(2, 1fr); */
    gap: 15px;
}

.overview-box {
    background: #09090B;
    color:#FFFFFF;
    padding: 10px;
    border-radius: 8px;
    text-align: start;
    flex: 1;
    border:1px solid #A1A1A1;
    padding:20px 20px;
}

.overview-box h6 {
    font-size: 0.8rem;
    font-weight: 500;
    margin-bottom: 20px;
}

.overview-box p {
    font-size: 0.8rem;
    margin:0px;
    font-weight: 500;
    margin: 0px;
}

.overview-box small {
    color: #979A9C;
    font-size: 0.6rem;
    margin:0px;

}

/* Notification Tabs */

.notification_tab_container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    padding: 0px 15px 0px 0px;
}



.notification-tabs {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    background-color: #27272A;
    padding:8px 14px;
    border: 0.5px solid #27272A;
    border-radius: 10px;
}

.notification-tabs button:hover,
.notification-tabs button {
    border: none;
    cursor: pointer;
    font-size: 0.7rem;
    font-weight: 500;
    color:#A1A1A1;
}

.notification-tabs .btn.active{
    background: #09090B;
    color: #FFFFFF;
    padding: 4px 10px;
    border-radius: 6px;
}


.notification_tab_container .view_all{
    color:#09090B;
    font-weight: 700;
    padding: 10px 30px;
    font-size:0.65rem;
    border-radius: 5px;
    border: 0.5px solid #C4C4C4;
    background-color: #C4C4C4;
}






/* Notification List */
.notification-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
    /* max-height: 300px; */
     /* Keep scrollable */
    /* overflow-y: auto; */
    padding-right: 5px;
}

.notification-item {
    display: flex;
    flex-direction: column;
    gap: 2px;
    background: #09090B;
    border-radius: 10px;
    border: 0.5px solid #A1A1A1;
    padding: 15px 30px;
    border-radius: 6px;
}

.notification-item-header{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap:20px;
    margin-bottom: 15px;
}

.notification-item-header p{
    color: #ffffff;
    font-size: 0.75rem;
    font-weight: 500;
    margin:0;
}

.notification-item-header small{
    color:#09090B;
    background-color: #ffffff;
    border-radius: 8px;
    font-size: 0.6rem;
    padding:3px 15px;
    font-weight: 700;
    margin:0;
}

.notification-item-body{
    display: flex;
    justify-content: space-between;
    align-items: start;
}

.notification-item-body .left h6{
    color: #ffffff;
    font-weight: 200;
    font-size: 0.8rem;
    margin: 0;
}

.notification-item-body .left small{
    color:#A1A1A1;
    font-size: 0.7rem;

}

.notification-item-body .right{
    display: flex;
    align-items: center;
    gap:60px;
}

.notification-item-body .right p{
    color: #ffffff;
    font-weight: 500;
    font-size: 0.75rem;
    margin: 0;
}

.notification-item-body .right small{
    color: #ffffff;
    font-weight: 500;
    font-size: 0.8rem;
    background-color: #27272A;
    border-radius: 8px;
    font-size: 0.6rem;
    padding:4px 20px;
    margin: 0;
}


/* Responsive */
@media (max-width: 480px) {
    .notification-modal {
        width: 100%;
        height: 100%;
        border-radius: 0;
    }
}