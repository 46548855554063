.preview-container {
  display: flex;
  height: 100vh;
  background-color: #27272a;
  border-radius: 10px;
}

.mobile-payment-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.mobile-payment-button {
  align-items: center;
  padding-inline: 10px;
  padding-block: 5px;
  border-radius: 10px;
  margin-bottom: 10px;
}

.send-review {
  display: flex;
  justify-content: space-around;
  border-radius: 10px;
  background-color: #27272a;
  margin-bottom: 20px;
  align-items: center;
  padding: 20px;
}

.review-img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #2727;
  margin-right: 20px;
}

image {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #27272a;
}

.review-name {
  color: #a1a1a1;
  font-size: 14px;
}

.review-text {
  color: #fff;
  font-size: 12px;
}

.review-right-details {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
  align-items: center;
}

.review-date {
  color: #a1a1a1;
  font-size: 12px;
}

.review-reply {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #fff;
  font-size: 12px;
}

.receive-review {
  display: flex;
  flex-direction: column;
  justify-items: center;
  border-radius: 10px;
  background-color: #44484d;
  margin-bottom: 10px;
  align-items: end;
  padding-block: 25px;
  margin-left: 20%;
  padding-inline: 20px;
}

.post-review {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 10px;
  background-color: #27272a;
  margin-top: 10%;
  align-items: end;
  padding: 20px;
}

.review-input {
  width: 100%;
  height: 100%;
  background-color: #27272a;
  border: none;
  color: #fff;
  font-size: 12px;
  padding: 10px;
}

.review-button {
  align-items: end;
  display: flex;
  justify-items: end;
  padding-inline: 20px;
  padding-block: 5px;
  border-radius: 10px;
  margin-block: 10px;
}

.details-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.details-card {
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  border-radius: 10px;
  background-color: #1e1e1e;
  margin-bottom: 10px;
  padding-block: 30px;
  padding-left: 30px;
  border: 2px solid #a1a1a1;
  width: 48%;
}

.details-card-subtext {
  color: #a1a1a1;
  font-size: 12px;
  font-weight: 500;
}

.info {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 10px;
  padding-top: 20px;
}

.space {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 30px;
  margin-bottom: 20px;
  
}

.deliverable-title {
  color: #fff;
  font-size: 16px;
}

.subtext {
  color: #a1a1a1;
  font-size: 12px;
  font-weight: 500;
}

.subvalue {
  color: #fff;
  font-size: 16px;
  font-weight: 500;
}

.tasktitle {
  color: #a1a1a1;
  font-size: 16px;
}

.task-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-top: 20px;
}
.taskvalue {
  color: #fff;
  font-size: 14px;
}

.taskbutton {
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  padding-inline: 10px;
  padding-block: 5px;
  border-radius: 10px;
  background-color: #4c4c4c;
  cursor: pointer;
  min-width: 60px;
  display: flex;
  justify-content: center;
}

.version {
  color: #a1a1a1;
  font-size: 12px;
  font-weight: 500;
  display: flex;
  gap: 10px;
}

.strong {
  color: #fff;
  font-size: 16px;
  font-weight: 500;
}