/*** Talent Register Styling ***/



.talent_create_profile_section{
    display: flex;
    justify-content: center;
    align-items: center;
    width:100%;
    height:100dvh;
    padding: 20px 0px;
}


.talent_create_profile_section .name_box{
    display: flex;
    margin:0px !important;
    padding: 0px;
    gap:10px;
    width:100%;
    justify-content: space-between;
}

.talent_create_profile_section .name_box div{
    flex:1;
}


.location_box{
    display: flex;
    margin:0px;
    gap:10px;
    padding: 0px;
    width:100%;
    justify-content: space-between;
}

.location_box div{
    flex:1;
}



.talent_create_profile_section .row{
    margin:0;
    padding:0;
    width:100%;
    justify-content: center;
    align-items: center;
}

.talent_create_profile_section h1{
    text-align: center;
    font-size: 1.5rem;
    color:#FFFFFF;
}

.talent_create_profile_section p{
    color:#FFFFFF;
    font-size: 0.7rem;
    font-weight: 100;
    text-align: center;
}





.talent_create_profile_section label{
    font-size: 0.7rem;
    color: #A1A1A1 !important;
    z-index: 1;
}


.talent_create_profile_section input{
    background-color: #1E1E1E  !important;
    border: 1px solid #707070 !important;
    color:#FFFFFF !important;
}

.talent_create_profile_section textarea{
    background-color: #1E1E1E  !important;
    border: 1px solid #707070 !important;
    color:#FFFFFF !important;
}

.talent_create_profile_section select{
    background-color: #1E1E1E  !important;
    border: 1px solid #707070 !important;
    color:#A1A1A1 !important;
    flex-grow: 1;
}



.talent_create_profile_section form button{
    color:#FFFFFF;
    background-color: #A1A1A1;
    font-size: 0.8rem;
    font-weight: 700;
    margin-top: 30px;
    padding: 15px 0px;
}

.talent_create_profile_section form button{
    color:#000000 !important;
}

.talent_create_profile_section form button:hover{
    color:#000000 !important;
    background-color: #A1A1A1;
}


.talent_create_profile_section img{
    margin-top: 35px;
    width:30%;
}


.field-group {
    margin: 20px 0;
    position: relative;
}
  

.field-group input:focus {
    outline: none !important;
    border: 1px solid #A1A1A1;
}
  

.field-group input {
    padding: 10px 35px 10px 12px;
    font-size: 15px;
    width: 100%;
    border-radius: 5px;
    border: 1px solid #A1A1A1;
    outline: none;
}



.field-group input:focus+label,
.field-group input:not(:placeholder-shown)+label {
    background-color: #1E1E1E;
    color:#ffffff !important;
    padding: 0 5px;
    left: 8px;
    font-size: 0.65rem;
    top: 0;
    font-weight: 500;
    outline: none;
}
  

.field-group label {
    position: absolute;
    transition: top .4s, font-size .4s;
    left: 12px;
    font-size: 14px;
    top: 50%;
    color: #A1A1A1 !important;
    cursor: text;
    transform: translateY(-50%);
}



