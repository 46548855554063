/* New Task Styling */
.project_workspace_container{
    border-right: 1px solid #30363D;
}

/* .add_new_task form{
    width: max-contentaut
} */


.add_new_task{
    display: flex;
    flex-direction: column;
    height:calc(63dvh + 50px);
    gap:10px;
    align-items: center;
    margin: 0 auto; /* Centers the whole div */
    width: 100% !important; 
    background-color: #121212;
    padding: 15px 0px 0px 0px;
    position: relative;
}


.add_new_task .row{
    display: flex;
    gap:15px;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    /* padding:0; */
    /* margin:0 !important;
    padding:0 !important; */
}


.add_new_task .form_box{
    background-color: #4C4C4C !important;
    border: 1px solid #A1A1A1;
    border-radius: 10px;
    color: #A1A1A1 !important;
    font-size: 0.7rem !important;
    padding:10px 14px;
    margin:0;
    /* flex:1; */

}


/* .add_new_task .last_box{
    background-color: none !important;
    border: none !important;
} */

.add_new_task label{
    color:#FFFFFF;
    font-size: 0.6rem;
    /* position: relative; */
    margin-bottom:5px;
}


.add_new_task textarea,
.add_new_task select,
.add_new_task input{
    background-color: #4C4C4C !important;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    padding:0 !important;
    color: #A1A1A1 !important;
    font-size: 0.7rem !important;
    /* height: 30px; */
    width:100%;
    outline: none;
}

.add_new_task select:focus,
.add_new_task input:focus {
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
}



.last_row_targ{
    display: flex;
    flex-direction: column;
    margin:0;
    gap:10px !important;
    padding:0;
    width:100%;
}


.add_new_task .attachment_box{
    margin-bottom: 20px;
}


.add_new_task .attachment_btn{
    border: 1px solid #A1A1A1;
    border-radius: 8px;
    padding: 3px 15px;
    font-size: 0.65rem;
    color:#A1A1A1;
    margin-bottom: 7px;
}

.add_new_task .attachments-preview{
    display: flex;
    flex-wrap: wrap;
}


.add_new_task .attachments-preview span{
    color: #FFFFFF;
    font-size: 0.6rem;
    font-size: 400;
    margin:0 !important;
    padding:0 !important;
}

.add_new_task .attachment-item{
    margin:0 !important;
    padding:0 !important;
}


.add_new_task .assignee-container{
    min-height:15px;
    cursor: pointer;

}

.add_new_task .attachment_and_assignee p{
    font-size: 0.65rem;
    color:#A1A1A1;
    font-weight: 500;
}


.add_new_task .search_task_user{
    background-color: #FFFFFF !important;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    color: #000000 !important;
    font-size: 0.8rem !important;
    height:45px;
    padding:5px 15px !important;
    margin-bottom: 5px;
}

.add_new_task .search-results{
    max-height: 120px;
    overflow-y: auto;
    overflow-x: hidden;

}

.add_new_task .assignee-tag{
    display: inline-flex;
    margin:0;
    align-items: center;
    color:#FFFFFF;
}

/* .add_new_task .assignee-tag span{
    margin-right: 15px;
} */


.add_new_task .remove-btn{
    color:#FFFFFF;
    margin:0;
    margin:0 !important;
    padding:0 !important;
    margin-left: 10px !important;
}



.add_new_task .assignee_deets{
    display: flex !important;
    align-items: center;
    padding: 6px 10px;
    background-color: #FFFFFF;
    margin-bottom: 5px;
}

.add_new_task .assignee_deets img{
    margin-right: 6px;
    width: 40px;
    height: 40px;
    border-radius: 50%;

}

.add_new_task .assignee_deets p{
    margin:0;
}


.add_new_task .assignee_deets p:nth-of-type(1){
    color: #1E1E1E;
    font-size: 0.8rem;
    font-weight: 500;
}



/* .last_row_targ .col{
    width:100%;
    flex-grow:1 !important;
} */

/* 
select{
    background-color: #4C4C4C !important;
    border: 1px solid #A1A1A1;
    border-radius: 10px;
    color: #A1A1A1 !important;
    font-size: 0.6rem !important;
} */














/* .add_new_task_body .row{
    display: flex;
    justify-content: center;
    align-items: center;
    gap:10px;
   
} */


/* .add_task_question_header{
    padding: 0px !important;
} */


/* .add_new_task_body .col-lg-5{
    background-color: #4C4C4C !important;
    border: 1px solid #A1A1A1;
    border-radius: 10px;
    color: #A1A1A1 !important;
    font-size: 0.6rem !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding:5px 14px;

} */





/* .add_new_task_body select,
.add_new_task_body input{
    background-color: #4C4C4C !important;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    padding:0 !important;
    color: #A1A1A1 !important;
    font-size: 0.6rem !important;
    height: 30px;
    width:100%;
    outline: none;
} */

/* .add_new_task_body select:focus,
.add_new_task_body input:focus {
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
} */



/* select{
    background-color: #4C4C4C !important;
    border: 1px solid #A1A1A1;
    border-radius: 10px;
    color: #A1A1A1 !important;
    font-size: 0.6rem !important;
} */




/* .linked_to_info_box{
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin: 0px !important;
    width: 100%;
    height: auto;
} */


/* .linked_to_info_box h4{
    color: #FFFFFF;
    font-weight: 700;
    font-size: 0.7rem;
    margin: 0;
} */


/* .linked_to_info{
    display: flex;
} */


/* .linked_to_info .col{
    flex:1;
} */


/* .linked_to_info .col div{
    display: flex;
    justify-content: start;
    align-items: center;
    gap:15px;
    margin:0px 0px 8px 0px;
} */

/* .linked_to_info .col h6{
    color:#FFFFFF;
    font-weight: 500;
    margin:0;
    font-size: 0.75rem;

} */


/* .project_info_tick_icon{
    width:10%;
    height: 20%;
} */


/* .project_info_check_icon{
    width:8%;
    height: 16%;
    margin-right: 12px;
} */


/* 
.linked_to_info ul li{
    color: #FFFFFF;
    font-size: 0.55rem;
    list-style-type: none;
    font-weight: 200;
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    
} */



/* .project_info_save_box{
    position: absolute;
    right: 0;
    bottom: -53px;
    z-index: 3;
} */

/* .save_button:hover, */
.save_button{
    position: absolute;
    right: 25px;
    bottom: -53px;
    z-index: 3;
    background-color: #FF8C27;
    border: 0.5px solid #A1A1A1;
    padding: 8px 20px;
    width: max-content;
    border-radius: 8px;
    color: #000000;
    font-size: 0.7rem;
    font-weight: 500;
}


.save_button:hover{
    background-color: #FF8C27;
    border: 0.5px solid #A1A1A1;
    color: #000000;
}