/*** Dashboard Nav Styling ***/

#dashboard_nav {
    display: flex;
    flex-direction: column;
    height: 100dvh;
    border-right: 1px solid #30363D;
    padding-left: 5px;
    justify-content: start;
    align-items: start;
    width: var(--sidenav-width);
    flex: 0 0 var(--sidenav-width);
    transition: width 0.3s ease-in-out;
}


.dashboard_nav_logo_container {
    position:relative;
    width: 100%;
    display: flex;
    align-items: center;
    height: 45px;
    justify-content: start;
    padding: 0px;
    margin-top: 15px;
}

.dashboard_nav_logo_container .navbar-brand img {
    height: 2.1rem !important; /* Ensure the logo appears larger */
    max-height: none;
    width: auto; /* Maintain aspect ratio */
    margin-left: 12px; /* Optional spacing */
}


.sb-sidenav {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    width: 100%;
}

.sb-sidenav .sb-sidenav-menu {
    flex-grow: 1;
}
  



.sb-sidenav-menu .nav-link {
    display: flex;
    align-items: center;
    color: #C4C4C4;
    padding: 15px 0px 12px 15px;
    font-size: 0.75rem;
}


.sb-sidenav-menu .nav-link.active {
    color: #FF8C27;
}


.sb-nav-link-icon{
    margin:0 !important;
}



.sb-sidenav-menu{
    padding:0;
}


.sb-sidenav-menu svg{
    width:16px;
    margin-right:15px;
}


/* .sb-sidenav-menu img {
    width: 50%;
    height: auto;
} */


.sb-sidenav-menu hr{
    border: 1px solid #30363D;
    width: 90%;
    margin: 1 auto;
    display: flex;
    justify-self: center;
}



#layoutSidenav_nav.hidden {
    transform: translateX(-100%); /* Completely hide off the screen */
}


/* @media (min-width: 768px) and (max-width: 1024px) {
    

}  */

/* Small Screens: Hidden by default */
@media (max-width: 767px) {
    #layoutSidenav_nav {
      width: 180px; /* Off-canvas width */
      transform: translateX(-100%); /* Initially hidden */
      position: fixed; /* So it acts like an overlay */
      top: 0;
      left: 0;
      height: 100%;
    }
}
