/* Login Page Styling */


.signin_section{
    display: flex;
    justify-content: center;
    align-items: center;
    width:100%;
    margin:0px;
    padding: 10px 0px 25px 0px;
    
}


.signin_section .row{
    margin:0;
    padding:15px 0px 0px 0px;
    width:100%;
    justify-content: center;
    align-items: center;
}


.signin_section h1{
    text-align: center;
    font-size: 2rem;
    color:#FFFFFF;
}


.signin_section p {
    color:#FFFFFF;
    font-size: 0.9rem;
    font-weight: 100;
    text-align: center;
    margin-bottom: 35px;
}



.signin_section label{
    font-size: 0.7rem;
    color: #A1A1A1 !important;
    z-index: 1;
}



.signin_section input{
    background-color: #1E1E1E  !important;
    border: 1px solid #707070 !important;
    color:#FFFFFF !important;
    height:55px;
}



.signin_section .toggle-password {
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
    cursor: pointer;
    font-size: 1rem;
    color: #707070;
    position: absolute;
    z-index: 2;
}


.signin_section .email_icon{
    position: absolute;
    z-index: 2;
    bottom: 50%;
    right: 14px;
    transform: translateY(50%);
    width:20px;
    height:auto;
}

@media (width:540px){
    .signin_section .email_icon{
        width:5% !important;
    }
}

@media (max-width:767px) {
    .signin_section .email_icon{
        width:7%;
        bottom: 15%;
    }
}

@media only screen and (min-width: 768px) and (max-width:1024px){
    .signin_section .email_icon{
        width:6%;
        bottom: 5%;
        right:13px;
    }
}


.fogot_pass_box{
    margin-top: 15px;
    margin-bottom:60px !important;
}

.signin_section .forgot_password{
    text-decoration: none;
    color:#FFFFFF;
    font-size: 0.9rem; 
}


.signin_section .d-grid{
    height: 60px !important;
}

.signin_section form .active-btn:hover,
.signin_section form .active-btn{
    color:#000000;
    background-color: #FFFFFF;
    font-size: 0.8rem;
    text-transform:uppercase;
    font-weight: 700;
}

.signin_section form .disabled-btn{
    color:#FFFFFF !important;
    background-color: #A1A1A1;
    font-size: 0.8rem;
    text-transform:uppercase;
    font-weight: 700;
    cursor: not-allowed;
}


.signin_section p:nth-of-type(2){
    margin-top: 25px;
    font-weight: 300;
}


.signin_section .account_link{
    text-decoration:none;
    color: #FFFFFF;
    cursor: pointer;
    font-weight: 500;

}


.signin_section hr{
    background-color: #FFFFFF;
    height:3px;
    margin: 45px 0px;
}


.google_sign_in_button_box{
    position: relative;
    display: grid;
    align-items: center;
}



.signin_section button{
    font-size: 0.8rem;
    color: #A1A1A1;
    background-color: #30363D;
    position: relative;
    width: 100%; /* Ensure the button spans the full width of the grid */
    text-align: center;
    height:60px;
}

.google_icon{
    position: absolute;
    bottom: 50%; /* Center vertically within the button */
    left: 1rem; /* Position at the left side of the button */
    transform: translateY(50%); /* Ensure perfect vertical alignment */
    width: 2rem !important;
    height: auto;
    pointer-events: none;
}


.signin_section button:hover{
    color:#808080;
    background-color: #F9F9F9;
}


.signin_section img{
    margin-top: 50px;
    width:17%;
}




.field-group {
    margin: 20px 0;
    position: relative;
}
  


.field-group input:focus {
    outline: none !important;
    border: 1px solid #A1A1A1;
}
  


.field-group input {
    padding: 10px 35px 10px 12px;
    font-size: 15px;
    width: 100%;
    border-radius: 5px;
    border: 1px solid #A1A1A1;
    outline: none;
}

  
.field-group input:focus+label,
.field-group input:not(:placeholder-shown)+label {
    background-color: #1E1E1E;
    color:#ffffff !important;
    padding: 0 5px;
    left: 8px;
    font-size: 0.65rem;
    top: 0;
    font-weight: 500;
    outline: none;
}
  


.field-group label {
    position: absolute;
    transition: top .4s, font-size .4s;
    left: 12px;
    font-size: 14px;
    top: 50%;
    color: #A1A1A1 !important;
    cursor: text;
    transform: translateY(-50%);
}
