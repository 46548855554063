.ru_input2 {
  height: 17px !important;
  margin-bottom: 0px !important;
  background-color: #4c4c4c !important;
  width: 70%;
}
.text14px600 {
  font-size: 14px;
  font-weight: 600;
  color: white;
  line-height: 21px;
  margin-bottom: 0;
}
