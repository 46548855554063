/* Projects Page Styling */

.new_project_quick_link{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top:15px;
    padding: 0px 45px 0px 20px; 
}

.new_project_quick_link img{
    width:35px;
    height: auto;
}

.new_project_link{
    display: flex;
    gap:10px;
    align-items: center;
}

.new_project_link img{
    width:25px;
    height: auto;
}


.new_project_quick_link .btn{
    color: #FFFFFF;
    background-color: #30363D;
    font-size: 0.8rem;
    padding: 10px 10px;
    display: flex;
    align-items: center;
}

.new_project_quick_link .btn img{
    width:30px;
    margin-right: 10px;
}