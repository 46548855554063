/* Project Workspace Header Styling */




.ProjectWorkspaceBody_header{
    display:flex;
    margin: 15px 0px 10px 0px;
    /* padding:0; */
    padding:10px 5px;
}



.ProjectWorkspaceBody_header  .left{
    color:#FFFFFF;
    display: flex;
    align-items: center;
}



.ProjectWorkspaceBody_header p{
    font-size: 1.1rem;
    color: #FFFFFF;
}




.ProjectWorkspaceBody_header .left .back-btn{
    display: flex;
    align-items: center;
}


.ProjectWorkspaceBody_header .left .back_icon{
    width: auto;
    height:16px;
}


.ProjectWorkspaceBody_header .left button:nth-of-type(2){
    background-color: #FFFFFF;
    color:#000000;
    border: 1px solid #A1A1A1;
    padding: 6px 20px;
    margin: 0 0 0 25px;
    border-radius: 8px;
    font-size: 0.6rem;

}


.ProjectWorkspaceBody_header .right{
    display: flex;
    justify-content: end;
    gap:20px
}

.ProjectWorkspaceBody_header .right button:nth-of-type(1){
    background-color: #09090B;
    color:#FFFFFF;
    border-radius: 8px;
    border: 0.5px solid #A1A1A1;
    font-size: 0.6rem;
    padding: 0px 18px 0px 9px;

}


.edit_project_icon{
    width:15px;
    height: auto;
    margin: 0px 18px 0px 0px;

}

.ProjectWorkspaceBody_header .right .task_btn{
    background-color: #3E3E3E;
    color:#FFFFFF;
    border-radius: 8px;
    display: flex;
    align-items: center;
    border: none;
    font-size: 0.6rem;
    padding: 0px 25px;

}
