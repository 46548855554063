/* Modal Overlay */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    z-index: 1000;
}

/* Modal Box */
.modal_content_box {
    background: #1c1c1c;
    color: white;
    padding: 20px;
    border-radius: 10px;
    width: 90%;
    max-width: 400px;
    position: absolute;
    top: 92px;
    right: 250px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
}


/* Close Button */
.modal-close {
    position: absolute;
    top: 15px;
    right: 15px;
    background: transparent;
    border: none;
    cursor: pointer;
}

/* Form Group */
.form-group {
    margin-top: 15px;
}