.wrapper {
  margin: 19px 30px 0 17px;
  width: 100%;
  max-height: 97vh;
  /* or calc(100vh - 4rem) etc. */
  overflow-y: auto;
  /* enable scrolling within the container */
  /* Hide scrollbar for Firefox */
  scrollbar-width: none;
  /* Hide scrollbar for IE, Edge */
  -ms-overflow-style: none;
}

/* Hide scrollbar for Chrome, Safari, and Opera */
.wrapper::-webkit-scrollbar {
  display: none;
}

.wrapper h2 {
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
}

.card {
  background: #1E1E1E;
  padding: 12px;
  border-radius: 10px;
  border: 0.5px solid #A1A1A1;
}

.dark {
  background: #09090B;
  padding: 22px;
}

.button {
  background: #fff;
  padding: 6px 18px;
  border: none;
  border-radius: 7px;
  font-weight: 700;
  font-size: 14px;
  height: 31px;
  line-height: 15px;
  color: #1e1e1e;
  cursor: pointer;
  width: fit-content;
}

.grid {
  display: grid;
  gap: 20px;
}

@media (min-width: 768px) {
  .grid-4 {
    grid-template-columns: repeat(4, 1fr);
  }
}

.status-pending {
  color: #FF8C27 !important;
}

.wrapper h6 {
  font-weight: 700;
  font-size: 15px;
  line-height: 22.5px;
}

.wrapper p {
  color: #979A9C;
  font-weight: 700;
}

/* Table Styles */
.table-container {
  overflow-x: auto;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  font-weight: 700 !important;
  font-size: 12px;
  line-height: 18px;
  padding: 12px;
  text-align: left;
}

thead {
  background: #27272A;
  color: #A1A1A1;
}

thead th:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

thead th:last-child {
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
}

.status-pending {
  color: var(--highlight);
}

.button-text {
  all: unset;
  font-weight: 300 !important;
  font-size: 12px;
  line-height: 18px;
}
