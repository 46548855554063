/* Project Brief */
.project_brief svg:nth-of-type(1){
  margin-right: 8px;
}


.project_brief {
  height: calc(63dvh + 50px);
  width: 100%;
  border: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px 0px;
  background-color: #121212;
  overflow-x: hidden;
  overflow-y: auto;
}


.project_brief .file_preview_frame .project_header{
  display: flex;
  justify-content: space-between;
  align-items: center;
}




.project_brief .file_preview_frame .file-found {
  color: #FFFFFF;
  display: flex;
  font-size: 0.7rem;
  gap: 3px;
  align-items: center;
  background-color: #1e1e1e;
  border-radius: 8px;
  padding: 6px 12px;
  border: 0.5px solid #a1a1a1;
}


.project_brief .file_preview_frame h4{
  font-size: 0.85rem;
  font-weight: 300;
  color: #FFFFFF;
  margin:0 0 7px 0;
}


.project_brief .file_preview_frame p{
  font-size: 0.75rem;
  font-weight: 300;
  color: #FFFFFF;
  margin:0;
}

.project_brief .file_preview_frame .brief_section{
  background-color: #09090B;
  border: 0.5px solid #A1A1A1;
  border-radius: 6px;
  padding: 12px 16px

}




/* .add_brief .text {
  font-size: 0.8rem;
  font-weight: 300;
  color: white;
  line-height: 24px;
} */



/* .add_brief p {
  margin-bottom: 0px;
} */
