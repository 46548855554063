/* :root {
  --bg-dark: #131313;
  --text-light: #fff;
  --text-muted: #aaa;
  --primary: #1f1f1f;
  --secondary: #333;
  --button-bg: #555;
} */

.wrapper {
  margin: 19px 30px 0 17px;
  width: 100%;
  max-height: 97vh;
  /* or calc(100vh - 4rem) etc. */
  overflow-y: auto;
  /* enable scrolling within the container */
  /* Hide scrollbar for Firefox */
  scrollbar-width: none;
  /* Hide scrollbar for IE, Edge */
  -ms-overflow-style: none;
  color: #fff;
}

/* Hide scrollbar for Chrome, Safari, and Opera */
.wrapper::-webkit-scrollbar {
  display: none;
}


.plan_container h2 {
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
}
.plan_container .plan {
  width: 173px;
  padding: 9px;
  background: #27272A;
  font-weight: 700;
  font-size: 15px;
  line-height: 22.5px;
  color: #fff;
}

.plan_container .card {
  background: #1E1E1E;
  padding: 12px;
  border-radius: 10px;
  border: 0.5px solid #A1A1A1;
}

.plan_container .dark {
  background: #09090B;
  padding: 22px;
}

.plan_container .button {
  background: #fff;
  padding: 6px 18px;
  border: none;
  border-radius: 7px;
  font-weight: 700;
  font-size: 14px;
  height: 31px;
  line-height: 15px;
  color: #1e1e1e;
  cursor: pointer;
  width: fit-content;
}

.plan_container .grid {
  display: grid;
  gap: 20px;
}

@media (min-width: 768px) {
  .grid-2 {
    grid-template-columns: repeat(2, 1fr);
  }
}

.plan_container .button.current-plan {
  background: #FF8C27;
}

.plan_container .plan_container h6 {
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
}

.plan_container .plan_container p {
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
}

.plan_container .plan_container h4 {
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
}

.plan_container .plan_container h4 span {
    font-weight: 300;
    font-size: 12px;
    line-height: 18px;
}
.plan_container .plan-card ul {
  list-style: none;
  padding: 0;
  margin-top: 15px;
}

.plan_container .plan-card ul li {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 16px;
  line-height: 46px;
}