.add_team_member_section{
  display: flex;
  flex-direction: column;
}

.add_team_member_body{
  display: flex;
  gap: 20px;
  align-items: start;
  padding: 0px 0px 0px 10px;

}


.add_team_member_body .col-md-5{
  flex:1;
}


.team_member_form{
  position: relative;
  display: flex;
  align-items: center;
  margin:0;
}


.team_member_form .dropdown-menu{
  position: absolute;
  top:80%;
  width:100%;
  max-height: 150px;
  overflow-y: auto;
  background-color: #09090b;
  z-index: 10;
  font-size: 0.75rem;
  font-weight: 500;
}


.team_member_form .dropdown-item{
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 10px;
}


.team_member_form .dropdown-item:hover{
  background-color: #09090b;
}


.team_member_form .dropdown-menu span{
  color:#FFFFFF
}


.team_member_form  .role_options{
  color:#FFFFFF
}


.team_member_form input{
  position: relative;
  width:100%;
  padding: 12px 40px 12px 12px; 
  background-color: #09090b !important;
  border-radius: 6px;
  border: 0.5px solid #a1a1a1;
  color: #ffffff;
  font-size: 0.75rem;
  font-weight: 500;
  height: 35px !important;
}

.team_member_form label{
  position: absolute;
  transform: translateY(-50%); /* Centers it vertically */
  right:20px;
  color: #ffffff;
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 1;
  pointer-events: none;
  background-color: #09090b; /* Match input to avoid overlap */
  /* padding: 0 5px; */
}


.custom_permission_box_and_invite{
  display: flex;
  justify-content: space-between;
  align-items: center !important;
  
}


.custom_permission_box_and_invite .custom-radio {
  appearance: none;
  width: 14px;
  height: 14px;
  border: 2px solid #FFFFFF;  /* White border */
  background-color: transparent;
  border-radius: 50%;
  display: inline-block;
  margin: 0px 5px 0px 0px;
  position: relative;
}

.custom_permission_box_and_invite .custom-radio:checked::before {
  content: "";
  width: 5px;
  height: 5px;
  background-color: #FFFFFF; /* White inner dot when selected */
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}




.custom_permission_box {
  display: flex;
  align-items: center;
  margin:0;
}




.custom_permission_link{
  cursor: pointer;
  margin:0;
  color:#ffffff;
  font-size: 0.7rem;
  margin:0;
}











.memberDetails {
  display: flex;
  flex-direction: column;
  width: 40%;
  gap: 12px;
}



.a1a1a112px500 {
  font-size: 12px;
  font-weight: 500;
  color: #a1a1a1;
  line-height: 15px;
}


.ru_blackDivInput {
  background-color: #09090b;
  border-radius: 6px;
  border: 0.5px solid #a1a1a1;
  color: #ffffff;
  font-size: 10px;
  font-weight: 500;
  display: flex;
  height: 32px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
}


.text10px700 {
  font-size: 10px;
  font-weight: 500;
  color: white;
  line-height: 15px;
}


.ru_input {
  height: 17px !important;
  margin-bottom: 0px !important;
  background-color: #09090b !important;
  width: 70%;
}


.ru_blackBtn {
  background-color: #09090b;
  border-radius: 6px;
  border: 0.5px solid #a1a1a1;
  color: #ffffff;
  font-size: 10px;
  font-weight: 500;
  display: flex;
  height: 31px;
  justify-content: center;
  align-items: center;
  width: 30%;
}
