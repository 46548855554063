/* Project Brief and Scope Styling */


.add_new_task{
    display: flex;
    flex-direction: column;
}

.project_workspace_container{
    border-right: 1px solid #30363D;
}



.add_new_task_body{
    position: relative;
    padding: 15px 50px 0px 8px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: start !important;
    background-color: #121212;
    margin:0px;
    height:100%;
}


.add_new_task_body .row{
    display: flex;
    /* justify-content: center;
    gap:10px;
    margin: 20px 0px 0px 0px; */
    
}



.add_new_task_body .col-lg-5{
    flex-grow: 1;
}

