/*** Project Workspace Chat ***/
.project_workspace_chat{
    min-width:300px;
    padding: 0px 3px;
}




.project_workspace_chat_head{
    display: flex;
    align-items: center;
    margin: 15px 0px 4px 0px;
    gap:16px;
    padding:10px 15px;

}


.project_workspace_chat_head h4{
    font-size: 0.9rem;
    color:#FFFFFF;
    font-weight: 400;
    margin:0;
}


.project_workspace_chat_head button{
    background-color: #30363D;
    color:#FFFFFF;
    padding: 8px 12px;
    font-weight: 500;
    font-size: 0.70rem;
    display: flex;
    align-items: center;
}

.project_workspace_chat_head button:hover{
    background-color: #30363D;
    color:#FFFFFF;
}


.project_workspace_chat_head button img{
    height: 14px;
    width: auto;
    margin-right: 8px;
}


.project_workspace_chat_head img{
    height: 20px;
    width: auto;
}


.project_workspace_chat_body {
    margin: auto 8px;
    padding:20px 7px;
    width:300px;
    height:87dvh;
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    background: #121212;
    border-radius: 8px;
}


.project_workspace_chat_body .page_title{
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* margin: 0 !important */

}

.project_workspace_chat_body .page_title h4{
    color:#B7BFC7;
    font-size: 0.9rem;
    font-weight: 300;
    margin: 0;
}

.project_workspace_chat_body .page_title button{
    background-color: #FF8C271A;
    color: #ff8c27;
    font-size: 0.5rem;
    border-radius: 18px;
    padding: 6px 22px
}


.project_workspace_chat_body .page_message{
    font-size: 0.632rem;
    color:#B7BFC7;
    font-weight: 700;
    
}


.project_workspace_chat_body .page_insight{
    color:#B7BFC7;
    font-size: 0.65rem;

}

.project_workspace_chat_body hr{
    background-color:#3E3E3E !important;
    width:100% !important;
    margin: 10px auto !important;
    border: 1px solid #3E3E3E
}



.project_workspace_chat_box {
    min-height:400px;
    max-height: 400px;
    display: flex;
    flex-direction: column;
    overflow-y: auto; 
    overflow-x: hidden; 
    scrollbar-width: thin;
    margin-bottom: 10px;
}


.project_workspace_chat_body .input-group textarea{
    background-color: #1E1E1E !important;
    color: #FFFFFF !important;
    font-size: 0.65rem;
    border:none;
    resize: none;
    overflow: auto;
    outline: none !important;
    /* width: 100%; */
}

.project_workspace_chat_body .input-group textarea:placeholder-shown {
    font-size: 0.7rem;
}


.project_workspace_chat_body .input-group textarea:focus {
    outline: none; 
    box-shadow: none;
}

.project_workspace_chat_body .input-group{
    display: flex;
    flex-direction: row !important;
    background-color: #1E1E1E;
    border-radius: 8px;
}

.project_workspace_chat_body .input-group,
.project_workspace_chat_body .input-group span{
    border:none;
    
}

.project_workspace_chat_body .input-group span{
    width:fit-content
}

.project_workspace_chat_body .input-group span{
    display: flex;
    gap:8px;
    justify-content: end;
}

.project_workspace_chat_body .input-group span div{
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    cursor: pointer;
}

.project_workspace_chat_body .input-group .voice_input{
    background: #FF8C27;
}

/* .project_workspace_chat_body .input-group span div:nth-of-type(2){
    background: #121212 !important;
    border: 1px solid #4C4C4C;
} */



.project_workspace_chat_body .conference_button{
    text-decoration: none;
    background: #121212 !important;
}

.project_workspace_chat_body .input-group span img{
    width:50%;
    cursor:pointer;
}

