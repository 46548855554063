/* Verify Email Page Styling */

.verify_email_section{
    display: flex;
    justify-content: center;
    align-items: center;
    width:100%;
    height:100dvh;
    margin:0px;
}

.verify_email_section .row{
    margin:0;
    padding:0;
    width:100%;
    justify-content: center;
    align-items: center;
}


.verify_email_section h1{
    text-align: center;
    font-size: 2rem;
    color:#FFFFFF;
    margin-top: 15px;
}


.verify_email_section p:nth-of-type(2),
.verify_email_section p:nth-of-type(1) {
    color:#FFFFFF;
    font-size: 0.9rem;
    font-weight: 100;
    text-align: center;
    margin: 0px 0px 7px 0px !important;
}


.verify_email_section p:nth-of-type(3){
    color:#FFFFFF;
    font-size: 0.9rem;
    font-weight: 100;
    text-align: center;
    margin: 0px 0px 35px 0px !important;
}

.verify_email_section .otp_box{
    display: flex;
    gap:10px;
}

.verify_email_section input.inactive{
    background-color: #1E1E1E  !important;
    border: 1px solid #707070 !important;
    height: 4rem;
    font-size: 1.5rem;
    color:#FFFFFF !important;
    text-align: center;
}

.verify_email_section input.active{
    background-color: #1E1E1E  !important;
    border: 1px solid #FFFFFF !important;
    height: 4rem;
    font-size: 1.5rem;
    color:#FFFFFF !important;
    text-align: center;
}

.verify_email_section .d-grid{
    margin-top: 30px !important;
    height: 60px !important;
}


.verify_email_section form .active-btn:hover,
.verify_email_section form .active-btn{
    color:#000000;
    background-color: #FFFFFF;
    font-size: 0.8rem;
    text-transform:uppercase;
    font-weight: 700;
}

.verify_email_section form .disabled-btn{
    color:#FFFFFF !important;
    background-color: #A1A1A1;
    font-size: 0.8rem;
    text-transform:uppercase;
    font-weight: 700;
    cursor: not-allowed;
}


.verify_email_section p:nth-of-type(4){
    color:#FFFFFF;
    font-size: 0.9rem;
    font-weight: 100;
    text-align: center;
    margin: 0px!important;
}

.verify_email_section .account_link{
    text-decoration:none;
    color: #FFFFFF;
    cursor: pointer;
    font-weight: 200;

}



.verify_email_section img{
    margin-top: 35px;
    width:30%;
}


