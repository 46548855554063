.text15px {
  font-size: 15px;
  font-weight: 500;
  color: white;
  line-height: 24px;
}
.text12px {
  font-size: 12px;
  font-weight: 400;
  color: white;
  line-height: 24px;
}
.text12pxBold {
  font-size: 12px;
  font-weight: 700;
  color: white;
  line-height: 24px;
}
.text14px600 {
  font-size: 14px;
  font-weight: 600;
  color: white;
  line-height: 21px;
}
.text10px700 {
  font-size: 10px;
  font-weight: 700;
  color: white;
  line-height: 15px;
}
.text16px600 {
  font-size: 16px;
  font-weight: 600;
  color: white;
  line-height: 24px;
}
.a1a1a111px500 {
  font-size: 11px;
  font-weight: 500;
  color: #a1a1a1;
  line-height: 24px;
}
.text22C55Epx400 {
  font-size: 12px;
  font-weight: 400;
  color: #22c55e;
  line-height: 18px;
}
.text24px {
  font-size: 24px;
  font-weight: 700;
  color: white;
  line-height: 24px;
}
.AddMemberBtn {
  padding: 12px 9px 12px 9px;
  background-color: #3e3e3e;
  border-radius: 6px;
  border: none;
  color: white;
  font-size: 10px;
  font-weight: 500;
}
.whiteBtn {
  padding: 12px 9px 12px 9px;
  background-color: #ffffff;
  border-radius: 5px;
  border: none;
  color: #09090b;
  font-size: 10px;
  font-weight: 700;
  width: 100%;
}
