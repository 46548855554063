/* Create New Password Page Styling */


.create_new_password_section{
    display: flex;
    justify-content: center;
    align-items: center;
    width:100%;
    height:100dvh;
    margin:0px;
    padding: 10px 0px 25px 0px;
    
}


.create_new_password_section .row{
    margin:0;
    padding:15px 0px 0px 0px;
    width:100%;
    justify-content: center;
    align-items: center;
}


.create_new_password_section h1{
    text-align: center;
    font-size: 2rem;
    color:#FFFFFF;
}


.create_new_password_section p {
    color:#FFFFFF;
    font-size: 0.91rem;
    font-weight: 100;
    text-align: center;
    margin-bottom: 35px;
}



.create_new_password_section label{
    font-size: 0.7rem;
    color: #A1A1A1 !important;
    z-index: 1;
}



.create_new_password_section input{
    background-color: #1E1E1E  !important;
    border: 1px solid #707070 !important;
    color:#FFFFFF !important;
    height:55px;
}

.create_new_password_section .toggle-password {
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
    cursor: pointer;
    font-size: 1rem;
    color: #707070;
    position: absolute;
    z-index: 2;
}

.create_new_password_section .email_icon{
    position: absolute;
    z-index: 2;
    bottom: 50%;
    right: 14px;
    transform: translateY(50%);
    width:20px;
    height:auto;
}

@media (width:540px){
    .create_new_password_section .email_icon{
        width:5% !important;
    }
}

@media (max-width:767px) {
    .create_new_password_section .email_icon{
        width:7%;
        bottom: 15%;
    }
}

@media only screen and (min-width: 768px) and (max-width:1024px){
    .create_new_password_section .email_icon{
        width:6%;
        bottom: 5%;
        right:13px;
    }
}




.create_new_password_section .d-grid{
    height: 60px !important;
    margin-top: 40px !important;
}

.create_new_password_section form .btn:hover,
.create_new_password_section form .btn{
    color:#000000;
    background-color: #FFFFFF;
    font-size: 0.8rem;
    text-transform:uppercase;
    font-weight: 700;
}






.create_new_password_section img{
    margin-top: 80px;
    width:30%;
}




.field-group {
    margin: 20px 0;
    position: relative;
}
  


.field-group input:focus {
    outline: none !important;
    border: 1px solid #A1A1A1;
}
  


.field-group input {
    padding: 10px 35px 10px 12px;
    font-size: 15px;
    width: 100%;
    border-radius: 5px;
    border: 1px solid #A1A1A1;
    outline: none;
}

  
.field-group input:focus+label,
.field-group input:not(:placeholder-shown)+label {
    background-color: #1E1E1E;
    color:#ffffff !important;
    padding: 0 5px;
    left: 8px;
    font-size: 0.65rem;
    top: 0;
    font-weight: 500;
    outline: none;
}
  


.field-group label {
    position: absolute;
    transition: top .4s, font-size .4s;
    left: 12px;
    font-size: 14px;
    top: 50%;
    color: #A1A1A1 !important;
    cursor: text;
    transform: translateY(-50%);
}
