.text10px700 {
  font-size: 10px;
  font-weight: 500;
  color: white;
  line-height: 15px;
}
.text10px700ff8c27 {
  font-size: 12px;
  font-weight: 700;
  color: #ff8c27;
  line-height: 15px;
}
.a1a1a112px500 {
  font-size: 12px;
  font-weight: 500;
  color: #a1a1a1;
  line-height: 15px;
}
.text14px700 {
  font-size: 14px;
  font-weight: 700;
  color: white;
  line-height: 21px;
}
.text12px700fbbc05 {
  font-size: 12px;
  font-weight: 700;
  color: #fbbc05;
  line-height: 21px;
}
.text12px50022c55e {
  font-size: 12px;
  font-weight: 500;
  color: #22c55e;
  line-height: 21px;
}
.ru_blackBtn {
  background-color: #09090b;
  border-radius: 6px;
  border: 0.5px solid #a1a1a1;
  color: #ffffff;
  font-size: 10px;
  font-weight: 500;
  display: flex;
  height: 31px;
  justify-content: center;
  align-items: center;
}
.ru_blackDiv {
  background-color: #09090b;
  border-radius: 6px;
  border: 0.5px solid #a1a1a1;
  color: #ffffff;
  font-size: 10px;
  font-weight: 500;
  display: flex;
  height: 32px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
}
.ru_whiteBtn {
  background-color: #ffffff;
  border-radius: 5px;
  border: none;
  color: #09090b;
  font-size: 10px;
  font-weight: 700;
  display: flex;
  height: 31px;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.taskbtn {
  width: 70px;
  height: 17px;
  border-radius: 7px;
  background-color: #27272a;
  justify-content: center;
  align-items: center;
  display: flex;
}
