/* Project Workspace Footer Styling */



.project_workspace_footer{
    margin:0px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    gap:15px;
    padding: 30px 15px 10px 15px ;
    flex-grow:1;
    background-color: #000000;
    border-top: 1px solid #30363D;
}

/* .project_workspace_footer .row{
    display: flex;
    flex-grow: 1;
} */

.project_workspace_footer .col{
    flex: 1 !important;
    height: max-content;
    margin: 0;
    padding:0px 5px;
    text-align: start;
}

/* 
.project_workspace_footer .col h4{
    
} */

.delivery_deets{
    display: flex;
    flex-direction: column;

}

.delivery_deets h4{
    color:#FFFFFF;
    font-size: 0.8rem;
    font-weight: 200;
}

.delivery_deets div{
    display: flex;
    gap:7px;
    padding:0;
}

.delivery_deets small{
    border: 1px solid #A1A1A1;
    color: #A1A1A1;
    border-radius:8px;
    font-size: 0.5rem;
    width: max-content !important;
    display: flex;
    align-items: center;
    margin:4px 0px 0px 0px;
    align-items: center;
    padding: 3px 6px;
}

.delivery_deets small img{
    width:18px;
    height:auto;
    margin-right: 4px;
}


.project_workspace_footer .collaborators{
    display: flex;
    flex-direction: column;
}

.project_workspace_footer .collaborators_info{
    display: flex;
    width: max-content;
    gap:7px;
    align-items: center !important;
}

.project_workspace_footer .collaborators div{
    position: relative;
    display: inline-flex; 
    margin: 0;
    padding: 0;
}

.project_workspace_footer .collaborators h4{
    color:#FFFFFF;
    font-size: 0.7rem;
    font-weight: 200;
}


.project_workspace_footer .collaborators img {
    position: relative;
    width: 27px; 
    height: 27px; 
    border-radius: 50%;
    margin:0px;
    margin-left: -20px; 
    z-index: 1; 
}

.project_workspace_footer .collaborators img:nth-of-type(1) {
    z-index: 1;
    margin-left: 0; 
}

.project_workspace_footer .collaborators img:nth-of-type(2) {
    z-index: 2;
}

.project_workspace_footer .collaborators img:nth-of-type(3) {
    z-index: 3;
}

.project_workspace_footer .collaborators img:nth-of-type(4) {
    z-index: 4;
}

.project_workspace_footer .collaborators small{
    color:#A1A1A1;
    font-size: 0.5rem;
    text-wrap: nowrap;
    margin:0px;

}


.tools_used{
    display: flex;
    flex-direction: column;
    width: max-content;

}

.tools_used h4{
    color:#FFFFFF;
    font-size: 0.7rem;
    font-weight: 200;
}

.tools_used div{
    display: flex;
    gap:2px;
}

.tools_used small{
    color: #A1A1A1;
    font-size: 0.55rem;
    width: max-content !important;
    display: flex;
    margin:0px;
    align-items: center;
    padding: 3px 0px;
}

.tools_used small img{
    width:18px;
    height:auto;
    margin-right: 4px;
}


.est_deleivery_date{
    display: flex;
    flex-direction: column;

}

.est_deleivery_date h4{
    color:#FFFFFF;
    font-size: 0.65rem;
    font-weight: 200;
}

.est_deleivery_date div{
    display: flex;
}

.est_deleivery_date small{
    color: #FFFFFF;
    font-size: 0.55rem;
    width: max-content !important;
    display: flex;
    margin:0px;
    align-items: center;
    padding: 3px 0px;
}

.est_deleivery_date small img{
    width:18px;
    height:auto;
    margin-right: 4px;
}
