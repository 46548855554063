/*** Talent Register Styling ***/



.talent_skills_n_exp_form_a_section{
    display: flex;
    justify-content: center;
    align-items: center;
    width:100%;
    padding: 30px 0px;
}


.group_box{
    display: flex;
    margin:0px;
    gap:10px;
    padding: 0px;
    width:100%;
    justify-content: space-between;
}

.group_box div{
    flex:1;
}



.talent_skills_n_exp_form_a_section .row{
    margin:0;
    padding:0;
    width:100%;
    justify-content: center;
    align-items: center;
}

.talent_skills_n_exp_form_a_section h1{
    text-align: center;
    font-size: 1.5rem;
    color:#FFFFFF;
}

.talent_skills_n_exp_form_a_section p{
    color:#FFFFFF;
    font-size: 0.8rem;
    font-weight: 500;
    text-align: start;
    padding:0px;
    margin:0px;
}

.talent_skills_n_exp_form_a_section small{
    color:#A1A1A1;
    font-size: 0.6rem;
    font-weight: 500;
    text-align: start;
    padding:0px;
    margin:0px;
}


.project_link_title{
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    gap:7px;
}


.project_link_box{
    border: 1px solid #707070 !important;
    padding: 10px 15px 20px 7px;

}





.talent_skills_n_exp_form_a_section label{
    font-size: 0.8rem;
    color: #FFFFFF !important;
    z-index: 1;
}


.talent_skills_n_exp_form_a_section input{
    background-color: #1E1E1E  !important;
    border: 1px solid #707070 !important;
    color:#FFFFFF !important;
}

.talent_skills_n_exp_form_a_section textarea{
    background-color: #1E1E1E  !important;
    border: 1px solid #707070 !important;
    color:#FFFFFF !important;
}

.talent_skills_n_exp_form_a_section select{
    background-color: #1E1E1E  !important;
    border: 1px solid #707070 !important;
    color:#A1A1A1 !important;
    flex-grow: 1;
}



.talent_skills_n_exp_form_a_section form button{
    color:#FFFFFF;
    background-color: #A1A1A1;
    font-size: 0.8rem;
    font-weight: 700;
    margin-top: 30px;
    padding: 15px 0px;
}

.talent_skills_n_exp_form_a_section form button{
    color:#000000 !important;
}

.talent_skills_n_exp_form_a_section form button:hover{
    color:#000000 !important;
    background-color: #A1A1A1;
}


.talent_skills_n_exp_form_a_section img{
    margin-top: 35px;
    width:30%;
}


.field-group {
    margin: 20px 0;
    position: relative;
}
  

.field-group input:focus {
    outline: none !important;
    border: 1px solid #A1A1A1;
}
  

.field-group input {
    padding: 10px 35px 10px 12px;
    font-size: 15px;
    width: 100%;
    border-radius: 5px;
    border: 1px solid #A1A1A1;
    outline: none;
}



.field-group input:focus+label,
.field-group input:not(:placeholder-shown)+label {
    background-color: #1E1E1E;
    color:#ffffff !important;
    padding: 0 5px;
    left: 8px;
    font-size: 0.65rem;
    top: 0;
    font-weight: 500;
    outline: none;
}
  

.field-group label {
    position: absolute;
    transition: top .4s, font-size .4s;
    left: 12px;
    font-size: 14px;
    top: 50%;
    color: #A1A1A1 !important;
    cursor: text;
    transform: translateY(-50%);
}



