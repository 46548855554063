.text12px500 {
  font-size: 12px;
  font-weight: 500;
  color: white;
  line-height: 21px;
}
.ru_input {
  margin-top: 16px;
  width: "100%";
  height: 66px !important;
  border-radius: 6px;
  background-color: #30363d !important;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 10px;
  color: white;
}
