/*** Project Workspace Styling ***/

.project_workspace_container{
    border-right: 1px solid #30363D;
    display: flex;
    flex-direction: column;
    flex-grow: 1; /* Take up all remaining height */
    overflow: hidden;
}



.ProjectWorkspaceBody{
    display: flex;
    flex-direction: column;
    flex-grow: 1; /* Take remaining space after header */
    overflow: hidden;
}


.ProjectWorkspaceBody_addressbar{
    flex-grow: 1;
    text-align: center;
    /* padding: 5px 5px; */
    display: flex;
    justify-content: space-between;
    align-items: center !important;
    background-color: #3E3E3E;
    margin:0px;
    height:50px;
}

.left_addressbar_quick_link{
    display: flex;
    justify-content:start;
    flex:0.3;
}

.left_addressbar_quick_link button img,
.right_addressbar_quick_link button img{
    width:12px !important;
    height:auto;
}


.right_addressbar_quick_link button{
    border: 1px solid #A1A1A1;
    border-radius: 12%;
    display: flex;
    align-items: center;
    padding:5px 9px;
    
}

.right_addressbar_quick_link{
    display: flex;
    justify-content:end;
    align-items: center;
    gap:12px;
    flex:0.5;
}



.right_addressbar_quick_link div{
    background-color: #09090B;
    border: 1px solid #A1A1A1;
    width: 30px;
    height:30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 15px;
}


.right_addressbar_quick_link div img{
    width:12px !important;
    height:auto;
    border: none !important
}



.ProjectWorkspaceBody .addy_bar{
    flex:1.2;
    margin:auto 0px;
    background-color: #121212;
    padding:7px 10px;
    margin:0px !important;
    border-radius: 12px;

}

/* .addy_bar{
    background-color: #121212;
    padding:7px 10px;
    margin:0px !important;
    border-radius: 12px;

} */

.addy_bar input{
    background: none;
    color:#A1A1A1;
    font-size: 0.7rem;
    border:none;
    outline: none !important; 
    box-shadow: none !important;
}

.addy_bar input:focus{
    outline: none !important; 
    box-shadow: none !important;
    border:none;
}


.preview_frame{
    width: 100%;  /* Full width of the viewport */
    height: 63dvh;  /* Adjust height as needed */
    border: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding:0;
    background-color: #121212;
}


.ghost_icon{
    width: 10%;
}


.preview_frame p{
    color: #FFFFFF;
    font-weight: 400;
    font-size: 0.8rem;

}


.preview_frame p:nth-of-type(1){
    margin-top: 20px;
}
