/***Project Workspace Nav Styling ***/


#project_workspace_nav{
    display: flex;
    flex-direction: column;
    height: 100dvh;
    justify-content: start;
    align-items: center;
    width: var(--sidenav-width);
    flex: 0 0 var(--sidenav-width);
    padding:10px 19px;
    border-right: 1px solid #30363D;
}




.project_workspace_logo_container {
    position:relative;
    width: 100%;
    display: flex;
    align-items: center;
    height: 45px;
    justify-content: start;
    margin-top: 20px;
    margin-bottom: 20px;
}


.custom-navbar-toggler {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 30px; /* Width of the button */
    height: 24px; /* Height of the button */
}


.toggler-icon {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 25px;
    width: 100%;
    padding: 5px 5px;
    border: 1px solid #C4C4C4;
    border-radius:15%;
}

.toggler-icon span {
    display: block;
    height: 2px; /* Thickness of each line */
    width: 100%;
    background-color: white; /* Set your desired line color */
    border-radius: 2px; /* Rounded edges for a smoother look */
    transition: all 0.3s ease-in-out;
}

/* Hover/Active state */
/* .custom-navbar-toggler:hover .toggler-icon span {
    background-color: #f39c12; 
} */

.custom-navbar-toggler.active .toggler-icon span:nth-child(1) {
    transform: rotate(45deg) translateY(8px);
}

.custom-navbar-toggler.active .toggler-icon span:nth-child(2) {
    opacity: 0; /* Hide the middle line when active */
}

.custom-navbar-toggler.active .toggler-icon span:nth-child(3) {
    transform: rotate(-45deg) translateY(-8px);
}

/* Adjust the logo size */
.project_workspace_logo_container .navbar-brand img {
    height: 1.9rem !important; /* Ensure the logo appears larger */
    max-height: none;
    width: auto; /* Maintain aspect ratio */
    margin-left: 8px; /* Optional spacing */
}


.project_workspace_nav_title_box{
    display: flex;
    justify-content: space-between;
    align-items: center;
    justify-content: space-between;
    width:100%;
    margin-bottom: 35px;
}


.project_workspace_nav_title_box .project_name{
    text-decoration: none;
}

.project_workspace_nav_title_box h4{
    margin:0;
    color:#FFFFFF;
    font-size: 0.9rem;
    cursor:pointer;
}


.project_workspace_nav_title_box .directory_box{
    display: flex;
    gap:7px;
    justify-content: end;
}


.project_workspace_nav_title_box .directory_box svg{
    cursor:pointer;
}


/* 
.project_workspace_nav_title_box img{
    width:35%;
    padding: 5px 5px;
    border:1px solid #A1A1A1;
    border-radius: 12%;
} */



#sidenavAccordion {
    width: 100%;
}




#sidenavAccordion .nav-link {
    font-size: 0.85rem;
    border-radius: 5px;
    text-decoration: none;
    transition: all 0.3s;
    color:#FFFFFF;
    
}

.sb-sidenav-menu-nested{
    margin-top: 13px;
}

#sidenavAccordion .sb-sidenav-menu-nested .nav-link {
    font-size: 0.75rem;
    padding:5px 0px;
    transition: all 0.3s ease-in-out;
    text-wrap: nowrap;
    display: flex;
    align-items: center;
    color:#C4C4C4;
    
}


#sidenavAccordion .sb-sidenav-menu-nested .nav-link.active{
    color:#FF8C27 !important;
}

/* #sidenavAccordion img{
    width:25px;
    height: auto;
    margin-right: 5px;
} */

#sidenavAccordion svg{
    margin-right: 6px;
}


#sidenavAccordion span{
    font-size: 0.759rem;
}

#project_workspace hr{
    width:100%;
    border: 1px solid #30363D;
    margin: 25px 0;
}

#sidenavAccordion .sb-sidenav-menu-nested .nav-link:hover {
    color: #adb5bd;
}

.sb-sidenav-collapse-arrow i {
    transition: transform 0.3s ease-in-out; /* Smooth rotation */
}

/* When collapsed (arrow down) */
.nav-link.collapsed .sb-sidenav-collapse-arrow i {
    transform: rotate(0deg);
}

/* When expanded (arrow up) */
.nav-link:not(.collapsed) .sb-sidenav-collapse-arrow i {
    transform: rotate(-180deg);
}
