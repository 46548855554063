/*******************************************************
  Container Layout
*******************************************************/
.calendar-time-container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: #1F1F1F; /* Dark background */
  color: #FFFFFF;           /* White text */
  padding: 20px;
  border-radius: 8px;       /* optional */
  max-width: 800px;         /* example width */
  margin: 0 auto;           /* center horizontally */
}

.calendar-wrapper {
  margin-right: 40px;
}

.time-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  max-height: 350px;
  width: 500px;
  overflow-y: auto;
  /* enable scrolling within the container */
  scrollbar-width: none;
  -ms-overflow-style: none;
}

/* Hide scrollbar for Chrome, Safari, and Opera */
.time-wrapper::-webkit-scrollbar {
  display: none;
}

/*******************************************************
  Time Slot Buttons
*******************************************************/
.time-slot {
  background: #3E3E3E;
  color: #fff;
  width: 136px;
  height: 49px;
  border: none;
  border-radius: 12px;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
}

.time-slot:hover {
  background-color: #3a3a3a;
}

.time-slot.active {
  background-color: #FF8C27;
  color: #fff;
}
/*******************************************************
  React-Calendar Overrides
*******************************************************/
/* Hide default border and background */
.react-calendar {
  background-color: transparent;
  border: none;
  color: #fff;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
}

/* Navigation (Month/Year arrows) */
.react-calendar__navigation {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.react-calendar__navigation button {
  background: none;
  border: none;
  color: #fff;
  font-size: 1.2rem;
  cursor: pointer;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: unset;
}

abbr[title] {
  text-decoration: none;
}
.nav-arrow {
  padding: 4px 8px;
}

/* Days grid */
.react-calendar__month-view__days__day {
  background: none;
  border-radius: 100%;
  width: 40px;
  height: 50px;
  text-align: center;
  margin: auto;
  cursor: pointer;
  transition: background-color 0.2s;
  color: #fff;
}


/* Active day (selected) */
.react-calendar__tile--active {
  background-color: #FF8C27;
  color: #fff;
  border-radius: 50%;
}

/* Today’s date highlight if you want a subtle highlight */
.react-calendar__tile--now {
  border: 1px solid #FF8C27;
  border-radius: 50%;
}

/* Remove extra styling around the tile */
.react-calendar__tile {
  border: none;
  outline: none;
}

.react-calendar__month-view__days__day--neighboringMonth:disabled,
.react-calendar__decade-view__years__year--neighboringDecade:disabled,
.react-calendar__century-view__decades__decade--neighboringCentury:disabled {
  color: #3E3E3E;
}

.react-calendar__tile:disabled, .react-calendar__navigation button:disabled {
  background-color: transparent;
  color: #3E3E3E;
}