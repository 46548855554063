/* General Container */
.submit_review_container {
    background: #121212;
    color: white;
    padding: 20px;
    min-height: 100vh;
    font-family: Arial, sans-serif;
  }
  
  /* Grid Layout */
  .submit_review_container .grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 15px;
  }
  
  @media (min-width: 768px) {
    .submit_review_container .grid {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  /* Input Boxes */
  .submit_review_container .input-box {
    background: #4c4c4c;
    padding: 15px;
    border-radius: 8px;
    border: 1px solid #a1a1a1;
  }

  .submit_review_container .input-box2 {
    background: #4c4c4c;
    padding-block: 30px;
    padding-inline: 15px;
    border-radius: 8px;
    border: 1px solid #a1a1a1;
  }
  
  .submit_review_container .input-box label {
    display: block;
    color: #fff;
    margin-bottom: 5px;
    font-weight: 700;
  }
  
  .submit_review_container .input-box input,
  .submit_review_container .input-box select,
  .submit_review_container textarea {
    width: 100%;
    padding: 8px;
    background: #4c4c4c;
    border: 1px solid #444;
    color: white;
    border-radius: 5px;
  }
  
  /* Text Areas */
  .submit_review_container textarea {
    height: 80px;
    resize: none;
  }
  
  /* Headings */
  .submit_review_container h2 {
    margin-top: 5%;
    font-size: 18px;
    color: #fff;
}
  
.submit_review_container p {
  color: #a1a1a1;
  font-size: 10px;
}