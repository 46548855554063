.ru_whiteBtn {
  background-color: #ffffff;
  border-radius: 5px;
  border: none;
  color: #09090b;
  font-size: 10px;
  font-weight: 700;
  display: flex;
  height: 31px;
  justify-content: center;
  align-items: center;
  width: 40%;
}
