.team_permission_frame{
  width: 100%;
  flex-grow: 1; /* Take remaining height */
  overflow: auto; /* Enable scrolling */
  padding: 16px; /* Optional padding */
  border: none;
  display: flex;
  flex-direction: column;
  /* align-items: center;
  justify-content: center; */
  background-color: #121212;
}


.team_permissions_title{
  color:#ffffff;
  font-size: 0.8rem;

}



.team_permissions_form_body .col-md-4{
  margin-bottom: 25px;
  
}



.permission_checkbox{
  display: flex;
  flex-direction: column;
}

.permission_checkbox label{
  margin-bottom: 5px;
}


.permission_checkbox span{
  color: #ffffff;
  font-size: 0.7rem;
}


.permission_checkbox .custom-radio {
  appearance: none;
  width: 14px;
  height: 14px;
  border: 2px solid #FBBC05;  /* White border */
  background-color: transparent;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
  position: relative;
}

.permission_checkbox .custom-radio:checked::before {
  content: "";
  width: 5px;
  height: 5px;
  background-color: #FBBC05; /* White inner dot when selected */
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}










.text10px300 {
  font-size: 10px;
  font-weight: 300;
  color: white;
  line-height: 15px;
}
.text16px600 {
  font-size: 16px;
  font-weight: 600;
  color: white;
  line-height: 24px;
  margin-bottom: 0;
}
.text12px400 {
  font-size: 12px;
  font-weight: 400;
  color: white;
  line-height: 18px;
}
.ru_2f2f2f_div {
  padding: 32px;
  background-color: #2f2f2f;
  border-radius: 6px;
  width: 65%;
  margin-top: 32px;
}
