/* Create Profile Page Styling */

.create_new_profile{
    display: flex;
    justify-content: center;
    width:100%;
    height:100%;
    margin:30px 0px;
    padding: 45px 5px;
}

.create_new_profile .row{
    display: flex;
    justify-content: center;
    align-items: start;
    gap:35px;
    flex-grow: 1;
}



.create_new_profile h1{
    color: #FFFFFF;
    font-size: 1.7rem;

}

.create_new_profile p:nth-of-type(1){
    color:#C4C4C4;
    font-size:1.05rem;
    font-weight: 100;
    word-spacing: 1px;
    margin-bottom: 45px;
}


.create_new_profile p:nth-of-type(2){
    color:#FFFFFF;
    font-size:1.05rem;
    font-weight: 300;
    word-spacing: 1px;
}





.create_new_profile .business_account_info_box{
    border: 1px solid #A1A1A1;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    padding: 25px 35px;


}

.create_new_profile .business_account_info{
    display: flex;
    margin-bottom: 18px;
}




.check_icon{
    width:15px;
    height: 15px;
    margin-right: 25px;
}

.create_new_profile .business_account_info h4{
    color:#FFFFFF;
    font-weight: 500;
    font-size: 0.85rem;
    margin:0px 0px 4px 0px;
}


.create_new_profile .business_account_info button{
    color:#1E1E1E;
    background-color: #FFFFFF;
    border:1px solid #1E1E1E;
    font-weight: 500 !important;
    font-size: 0.6rem !important;
    padding: 6px 6px !important;
    margin-top: 8px;
}

.create_new_profile .business_account_info small{
    color:#707070;
    font-size: 0.7rem !important;
    display: block;
    min-width: 320px;
    max-width: 320px;
    margin:0px !important;
}


.create_new_profile .business_account_info_box p{
    color:#FFFFFF;
    font-size:0.85rem;
    font-weight: 500;
    word-spacing: 1px;
    margin: 0px !important;
}

.create_new_profile .business_account_info_box small{
    color:#707070;
    font-size: 0.75rem;
    display: block;
    margin-bottom: 20px;
}


.business_account_info_box button{
    color:#1E1E1E;
    background-color: #FFFFFF;
    border:1px solid #1E1E1E;
    font-weight: 700 !important;
    font-size: 0.65rem !important;
    padding:12px 16px !important;
    width: max-content;
}




.profile_form{
    display: flex;
    flex-direction: column;

}

.profile_form p{
    color:#FFFFFF !important;
    font-size:1.05rem !important;
    font-weight: 300 !important;
    word-spacing: 1px;
    text-align: end;
    margin:0px !important;
}



.profile_form .btn{
    color:#1E1E1E;
    background-color: #FFFFFF;
    font-size: 0.8rem;
    font-weight: 700;
    padding: 10px 55px;
    height: 60px !important;
    margin-top: 25px;
}

.profile_img{
    position: relative;
    right: 0px;
    width: 120px;
    height: 120px;
    border-radius: 50%;
    border:1px solid #A1A1A1;
    color: #A1A1A1;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    align-self: flex-end;
    margin:0px 0px 17px 0px !important;
    /* object-fit: contain; */
}

.field-group {
    /* margin: 20px 0; */
    position: relative;
}
  


.field-group textarea:focus,
.field-group input:focus {
    outline: none !important;
    background-color: #1E1E1E;
}
  


.field-group textarea,
.field-group input {
    padding: 10px 35px 10px 12px;
    margin-bottom: 33px;
    font-size: 15px;
    width: 100%;
    border-radius: 5px;
    border: 1px solid #A1A1A1;
    outline: none !important;
    background-color: #1E1E1E !important;
    color: #FFFFFF !important;
    height:65px !important;
}





.field-group textarea:focus+label,
.field-group textarea:not(:placeholder-shown)+label,
.field-group input:focus+label,
.field-group input:not(:placeholder-shown)+label {
    background-color: #1E1E1E;
    color:#ffffff !important;
    padding: 0 5px;
    left: 8px;
    font-size: 0.65rem;
    top: 0;
    font-weight: 500;
    outline: none;
}
  


.field-group label {
    position: absolute;
    transition: top .4s, font-size .4s;
    left: 12px;
    font-size: 14px;
    top: 50%;
    color: #A1A1A1 !important;
    cursor: text;
    transform: translateY(-50%);
}
