/* Shared styling for both ScheduleModal and SelectTeamModal */

/* ========== OVERLAY & CONTAINER ========== */
.modal-overlay,
.team-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* dim background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.modal-container,
.team-modal-container {
  background-color: #1E1E1E;
  color: #fff;
  width: 50%;
  max-width: 90%;
  border-radius: 20px;
  padding: 20px;
  position: relative;
}

.modal-overlay {
  align-items: flex-start;
  overflow-y: auto;
  /* so the overlay itself can scroll if needed */
  padding: 2rem 0;
  /* some spacing from the top/bottom of the screen */
}

.modal-container {
  max-height: 90vh;
  /* or calc(100vh - 4rem) etc. */
  overflow-y: auto;
  /* enable scrolling within the container */
  /* Hide scrollbar for Firefox */
  scrollbar-width: none;
  /* Hide scrollbar for IE, Edge */
  -ms-overflow-style: none;
}

/* Hide scrollbar for Chrome, Safari, and Opera */
.modal-container::-webkit-scrollbar {
  display: none;
}

.hidden {
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
}

.header {
  display: flex;
  gap: 57px;
}

/* Close Button (X) */
.close-btn {
  all: unset;
  position: absolute;
  top: 17px;
  right: 17px;
  cursor: pointer;
}

/* ========== SCHEDULE MODAL MAIN ========== */
.modal-container h4 {
  font-weight: 600;
  font-size: 16px;
  line-height: 30px;
}

.modal-container .text {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #C4C4C4;
}

/* Top row with "Select a Day" & "Entire Team" toggle */
.top-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.select-team-btn {
  all: unset;
  display: flex;
  gap: 20px;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
}

h6 {
  font-weight: 500;
  font-size: 15px;
  line-height: 22.5px;
}


/* Meeting hint & link fields */
.meeting-link-container,.meeting-hint-container {
  background: linear-gradient(0deg, #4C4C4C, #4C4C4C);
  padding: 8px 10px;
  border-radius: 8px;
  border: 1px solid #A1A1A1;
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
  max-width: 370px;
  font-weight: 700;
  font-size: 10px;
  line-height: 13.5px;
  letter-spacing: 0%;
  height: 59px;
}

.meeting-label {
  color: #fff;
  /* White text for contrast */
}

.meeting-input {
  background: none;
  border: none;
  outline: none;
  font-size: 14px;
  color: #A1A1A1;
  width: 100%;
}

.meeting-input::placeholder {
  color: #777;
  /* Dim placeholder text */
}

/* Footer with final date/time & Schedule button */
.schedule-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #B7BFC7;
  padding-top: 14px;

}

.schedule-footer p {
  margin: 0;
  font-weight: 600;
  font-size: 0.95rem;
}

.schedule-confirm-btn {
  background-color: #fff;
  color: #1E1E1E;
  border: none;
  padding: 0.75rem 1.5rem;
  cursor: pointer;
  font-weight: 600;
  border-radius: 6px;
  font-size: 12px;
  line-height: 18px;
  width: 176px;
}

/* ========== SELECT TEAM MODAL ========== */


/* Modal Container */
.team-modal-container {
  width: 380px;
}

/* Modal Title */
.team-modal-container h3 {
    font-weight: 600;
    font-size: 16px;
    line-height: 30px;
    margin-bottom: 25px;
}

/* Search Bar */
.team-search {
  position: relative;
  margin-bottom: 1rem;
}

.team-search input {
  width: 100%;
  padding: 0.75rem 2.5rem 0.75rem 1rem;
  background-color: #3E3E3E;
  border: none;
  color: #C4C4C4;
  border-radius: 12px;
  font-size: 12px;
}

.team-search input::placeholder {
  color: #c4c4c4;
}

.team-search::after {
  /* content:  url('../static/images/search.svg'); */
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 1rem;
  color: #888;
}

/* Individual Team Item */
.team-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  border-radius: 6px;
  cursor: pointer;
  transition: background 0.2s ease-in-out;
}

/* Team Name and Availability */
.team-item div {
  display: flex;
  flex-direction: column;
}

.team-item span {
  color: #fff;
    font-size: 14px;
    line-height: 21px;
}

.team-item small {
    font-size: 10px;
    line-height: 15px;
  color: #c4c4c4;
  margin-top: 6px;
}

/* Checkbox */
.team-item input[type="checkbox"] {
  appearance: none;
  width: 18px;
  height: 18px;
  border: 1px solid #fff;
  border-radius: 3px;
  position: relative;
  cursor: pointer;
  transition: background 0.2s;
}

.team-item input[type="checkbox"]:checked::after {
  content: "✔";
  font-size: 0.6rem;
  color: #fff;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-weight: bold;
}

.text-right {
  text-align: right;
  margin-top: 67px;
}
/* Continue Button */
.continue-btn {
  width: 176px;
  background-color: #c4c4c4;
  color: #1E1E1E;
  height: 50px;
  border: none;
  padding: 0.75rem;
  cursor: pointer;
  font-weight: 600;
  border-radius: 6px;
  font-size: 12px;
  line-height: 18px;
  transition: background 0.2s ease-in-out;
}

.continue-btn:hover {
  background-color: #e5e5e5;
}

/* ========== RESPONSIVE ADJUSTMENTS ========== */
@media (max-width: 768px) {
  .schedule-body {
    flex-direction: column;
  }

  .calendar-section {
    width: 100%;
  }
}