/* Login Page Styling */


.password_reset_section{
    display: flex;
    justify-content: center;
    align-items: center;
    width:100%;
    height: 100dvh;
    margin:0px;
    padding: 10px 0px 25px 0px;
    
}


.password_reset_section .row{
    margin:0;
    padding:15px 0px 0px 0px;
    width:100%;
    justify-content: center;
    align-items: center;
}


.password_reset_section h1{
    text-align: center;
    font-size: 2rem;
    color:#FFFFFF;
}


.password_reset_section p {
    color:#FFFFFF;
    font-size: 0.9rem;
    font-weight: 100;
    text-align: center;
    margin-bottom: 15px;
}


.password_reset_section img:nth-of-type(1){
    width:170px;
}




.password_reset_section .change_email_link{
    color:#ffffff;
    text-decoration: none;
    font-size: 0.7rem;
}


.verify_email_title{
    color:#C4C4C4 !important;
    font-weight:100 !important;
}




.password_reset_section .logo{
    margin-top: 60px;
    width:20% !important;
}
