.ru_whiteBtn {
  background-color: #ffffff;
  border-radius: 5px;
  border: none;
  color: #09090b;
  font-size: 12px;
  font-weight: 600;
  display: flex;
  height: 31px;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.ant-progress .ant-progress-inner {
  background-color: #a1a1a1;
}
.ant-progress .ant-progress-bg {
  background-color: #ffffff;
}
.ant-progress .ant-progress-bg {
  height: 11px !important;
}
.ru_circle {
  width: 26px;
  height: 26px;
  background-color: #bee5c6;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.text222121 {
  font-size: 12px;
  font-weight: 300;
  color: #222121;
}
