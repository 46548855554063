/* Login Page Styling */


.password_updated_section{
    display: flex;
    justify-content: center;
    align-items: center;
    width:100%;
    height: 100dvh;
    margin:0px;
    padding: 10px 0px 25px 0px;
    
}


.password_updated_section .row{
    margin:0;
    padding:15px 0px 0px 0px;
    width:100%;
    justify-content: center;
    align-items: center;
}




.password_updated_section p {
    color:#FFFFFF;
    font-size: 0.9rem;
    font-weight: 100;
    text-align: center;
    margin-bottom: 30px;
}


.password_updated_section img{
    width:170px;
}

.password_updated_section .btn{
    height: 60px !important;
    color:#000000;
    background-color: #FFFFFF;
    font-size: 0.8rem;
    text-transform:uppercase;
    font-weight: 700;
}


.password_updated_section .logo{
    margin-top: 60px;
    width:20% !important;
}
