/*** Project Workspace Styling ***/

.project_workspace_container {
  border-right: 1px solid #30363d;
}

.ProjectWorkspaceBody {
  display: flex;
  flex-direction: column;
}

.empty_preview_frame {
  height: calc(63dvh + 50px);
  width: 100%;
  border: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0;
  background-color: #121212;
}

.ghost_icon {
  width: 10%;
}

.empty_preview_frame p {
  color: #ffffff;
  font-weight: 400;
  font-size: 0.8rem;
  margin-top: 20px;
}

.empty_preview_frame button:hover,
.empty_preview_frame button {
  background-color: #09090b;
  color: #ffffff;
  font-size: 0.65rem;
  font-weight: 500;
  padding: 6px 15px;
  display: flex;
  align-items: center;
}

.add_file_icon {
  width: 20%;
  margin-right: 8px;
}

/* .file_preview_frame p:nth-of-type(1){
    
} */

.file_preview_frame {
  height: calc(63dvh + 50px);
  width: 100%;
  border: none;
  display: flex;
  flex-direction: column;
  padding: 25px 15px;
  background-color: #121212;
}

.file_preview_frame input {
  background-color: #27272a;
  color: #a1a1a1;
  font-size: 0.8rem;
  border: none;
  outline: none;
  height: 40px;
  margin-bottom: 15px;
}

.file_menu_tabs {
  display: flex;
  flex-wrap: wrap;
  gap: 25px;
  background-color: #27272a;
  padding: 8px 8px;
  border: 0.5px solid #27272a;
  border-radius: 10px;
  margin-bottom: 15px;
}

.file_menu_tabs button:hover,
.file_menu_tabs button {
  border: none;
  cursor: pointer;
  font-size: 0.7rem;
  font-weight: 500;
  color: #a1a1a1;
}

.file_menu_tabs .btn.active {
  background: #09090b;
  color: #ffffff;
  padding: 4px 10px;
  border-radius: 6px;
  font-size: 0.7rem;
}

table th {
  color: #a1a1a1;
  font-size: 0.8rem;
  font-weight: 500;
}

table td {
  color: #ffffff;
  font-size: 0.8rem;
}

table th:nth-of-type(7),
table td:nth-of-type(7) {
  text-align: center;
}

.table_icon {
  width: 15px;
  height: auto;
}
