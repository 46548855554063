/* Register Page Styling */


.register_section{
    display: flex;
    justify-content: center;
    align-items: center;
    width:100%;
    padding: 20px 0px;
}


.register_section .name_box{
    display: flex;
    margin:0 !important;
    padding: 0px;
    gap:10px;
    width:100%;
    justify-content: space-between;
    position: relative;
    align-items: center;
}



.register_section .name_box div{
    flex:1;
    margin: 0;
}




.register_section .row{
    margin:0;
    padding:0;
    width:100%;
    justify-content: center;
    align-items: center;
}


.register_section h1{
    text-align: center;
    font-size: 2rem;
    color:#FFFFFF;
}


.register_section p{
    color:#FFFFFF;
    font-size: 0.9rem;
    font-weight: 100;
    text-align: center;
    margin-bottom: 20px;
}


.register_section label{
    font-size: 0.7rem;
    color: #A1A1A1 !important;
    z-index: 1;
}


.register_section input{
    background-color: #1E1E1E  !important;
    border: 1px solid #707070 !important;
    color:#FFFFFF !important;
}


.register_section .toggle-password{
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
    cursor: pointer;
    font-size: 1rem;
    color: #707070;
    position: absolute;
    z-index: 2;
}

.register_section .email_icon{
    position: absolute;
    z-index: 2;
    bottom: 50%;
    right: 14px;
    transform: translateY(50%);
    width:20px;
    height:auto;
}

@media (width:540px){
    .register_section .email_icon{
        width:5% !important;
    }
}

@media (max-width:767px) {
    .register_section .email_icon{
        width:7%;
        bottom: 15%;
    }
}


@media only screen and (min-width: 768px) and (max-width:1024px){
    .register_section .email_icon{
        width:6%;
        bottom: 5%;
        right:13px;
    }
}


.register_section form .active-btn:hover,
.register_section form .active-btn{
    color:#000000 !important;
    background-color: #ffffff;
    font-size: 0.8rem;
    text-transform:uppercase;
    font-weight: 500;
}

.register_section form .disabled-btn{
    color:#000000 !important;
    background-color: #A1A1A1;
    font-size: 0.8rem;
    text-transform:uppercase;
    font-weight: 500;
    cursor: not-allowed;
}



.register_section p:nth-of-type(2){
    margin-top: 25px;
    font-weight: 300;
}

.register_section .account_link{
    text-decoration:none;
    color: #FFFFFF;
    cursor: pointer;
    font-weight: 500;

}

.register_section hr{
    background-color: #FFFFFF;
    height:3px;
    margin: 35px 0px;
}


.google_sign_in_button_box{
    position: relative;
    display: grid;
    align-items: center;
}



.register_section button{
    font-size: 0.8rem;
    color: #A1A1A1;
    background-color: #30363D;
    position: relative;
    width: 100%; /* Ensure the button spans the full width of the grid */
    text-align: center;
    height:60px;
}


.google_icon{
    position: absolute;
    bottom: 50%; /* Center vertically within the button */
    left: 1rem; /* Position at the left side of the button */
    transform: translateY(50%); /* Ensure perfect vertical alignment */
    width: 2rem !important;
    height: auto;
    pointer-events: none;
}

.register_section .d-grid{
    height: 60px !important;
}


.register_section button:hover{
    color:#808080;
    background-color: #F9F9F9;
}

.register_section img{
    margin-top: 40px;
    width:25%;
}


.register_section .form_check_box{
    margin-top: 15px;
    margin-bottom:30px;

}

.register_section .form_check_box label{
    font-size:0.9rem;
}

.register_section .form-check-input:checked + .form-check-label {
    color: #ffffff !important;
}




.field-group {
    margin: 20px 0;
    position: relative;
}
  


.field-group input:focus {
    outline: none !important;
    border: 1px solid #A1A1A1;
}
  


.field-group input {
    padding: 10px 35px 10px 12px;
    font-size: 15px;
    width: 100%;
    border-radius: 5px;
    border: 1px solid #A1A1A1;
    outline: none;
    height:55px;
    margin: 0 !important;
}


.field-group input:focus+label,
.field-group input:not(:placeholder-shown)+label {
    background-color: #1E1E1E;
    color:#ffffff !important;
    padding: 0 5px;
    left: 8px;
    font-size: 0.75rem;
    top: 0;
    font-weight: 500;
    outline: none;
}
  


/* .name_box label{

} */


.field-group label {
    position: absolute;
    transition: top .4s, font-size .4s;
    left: 12px;
    font-size: 14px;
    top: 50%;
    color: #A1A1A1 !important;
    cursor: text;
    transform: translateY(-50%);
}






