.wrapper span {
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
}
.gap-79 {
    gap: 79px;
}

.progress-bar, .progress {
    height: 7px;
}

.progress  {
  background: #A1A1A1;
}

.pro {
    width: 117px;
}

.d-flex .badge {
    height: fit-content;
}


input, input::placeholder{
    color: #A1A1A1 !important;
}

.meeting-hint-container {
    position: relative;
}
.meeting-hint-container button {
    all: unset;
}
.meeting-hint-container .d-flex {
    position: absolute;
    right: 22px;
    top: 18px;
}

.continue-btn {
background: #FF8C27;
border: 0.5px solid #A1A1A1;
}