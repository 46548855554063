/* Dashboard Styling  */


#dashboard_containter{
    display: flex;
    flex-direction: column;
    border-top: 1px solid #30363D;
    min-height: calc(100dvh - 75px);
}




.dashboard_quick_links{
    display: flex;
    gap: 15px;
    justify-content: space-evenly;
    align-items: center;
    margin-top:15px;
    padding: 0px 30px 0px 15px ;
    flex-wrap: wrap;
}


.dashboard_quick_links button:hover,
.dashboard_quick_links button{
    color: #FFFFFF;
    background-color: #30363D;
    font-size: 0.8rem;
    padding: 10px 15px;
    font-weight: 200;
    display: flex;
    justify-content: start;
    align-items: center;
}


.dashboard_quick_links button img{
    width:23%;
    margin-right: 13px;
}


.dashboard_quick_links button .get_support{
    width:20% !important;
}


.dashboard_recently_viewed{
    padding: 0px 18px;
    margin-top: 18px;
}

/* .dashboard_recently_viewed .container{
    position: relative;
    overflow-x: auto;
} */

.dashboard_recently_viewed p{
    color:#FFFFFF;
    font-size: 0.85rem;
    padding-left:3px;

}

.dashboard_recently_viewed .row{
    display: flex;
    flex-direction: row;
    gap: 15px;
    padding: 0px 15px;

}

.dashboard_empty_preview{
    height:calc(63dvh);
    width: 100%;
    border: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding:0;
}


.dashboard_empty_preview .ghost_icon{
    width: 8%;
}


.dashboard_empty_preview p{
    color: #FFFFFF;
    font-weight: 400;
    font-size: 0.8rem;
    margin-top: 20px;

}


.dashboard_empty_preview button:hover,
.dashboard_empty_preview button{
    background-color: #09090B;
    color: #FFFFFF;
    font-size: 0.65rem;
    font-weight: 500;
    padding:6px 15px;
    display: flex;
    align-items: center;
}


.dasboard_preview_box{
    position: relative;
    overflow-y: scroll !important;
    overflow-x: hidden;
    max-height: calc(100dvh - 210px);
    overflow-y: auto;
    scrollbar-width: thin; /* For Firefox */
    scrollbar-color: transparent transparent;
}






.dashboard_recently_viewed .col-md-5{
    flex-grow: 1;
    border: 1px solid #30363D;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height:195px;
    padding: 20px 25px;
    cursor: pointer;
}

.dashboard_recently_viewed .col-md-5 p{
    font-size: 0.8rem;
}

.dashboard_recently_viewed .card_top,
.dashboard_recently_viewed .card_bottom{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.dashboard_recently_viewed h5{
    padding:7px 20px;
    font-size: 0.65rem;
    border-radius: 20px;
    margin:0;

}

.dashboard_recently_viewed span{
    color: #A1A1A1;
    font-size: 0.85rem;
}

.dashboard_recently_viewed .card_bottom{
    display: flex;
    justify-content: space-between;
}

.dashboard_recently_viewed .card_bottom span {
    font-size:0.7rem ;
    flex:1
}

.dashboard_recently_viewed .card_bottom div {
    position: relative;
    display: inline-flex;
    justify-content: end;
    margin: 0;
    padding: 0;
    /* flex:0.3 */
    flex:0.4
}

.dashboard_recently_viewed .card_bottom img {
    position: relative;
    width: 35px; 
    height: 35px; 
    border-radius: 50%;
    margin-left: -20px; 
    z-index: 1;
}

.dashboard_recently_viewed .card_bottom img:nth-of-type(1) {
    z-index: 1;
    margin-left: 0; 
}

.dashboard_recently_viewed .card_bottom img:nth-of-type(2) {
    z-index: 2;
}

.dashboard_recently_viewed .card_bottom img:nth-of-type(3) {
    z-index: 3;
}

.dashboard_recently_viewed .card_bottom img:nth-of-type(4) {
    z-index: 4;
}

.dashboard_recently_viewed h5.active-status {
    color: #74FF80;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), rgba(255, 140, 39, 0.1);
}

.dashboard_recently_viewed h5.pending-status {
    color: #FF8C27;
    background: rgba(255, 140, 39, 0.1);
}

.dropdown-toggle::after {
    display: none !important;
}