/* New Project Page Styling */

#dashboard_containter{
    width:100vw;
}



.new_project_container{
    padding: 0px 20px;
    margin-top:20px;
}

.new_project_header h4{
    color: #ffffff;
    font-size: 1.2rem;
}

.new_project_header p{
    color:#B7BFC7;
    font-size:0.9rem
}

.new_project_chat_body {
    margin-top: 5px;
    padding:10px 17px;
    width:95%;
    min-height:465px;
    max-height: 465px;
    display: flex;
    flex-direction: column;
    background: #121212;
    border-radius: 12px;
}



.new_project_chat_box {
    min-height:355px;
    max-height: 355px;
    display: flex;
    flex-direction: column;
    overflow-y: auto; 
    overflow-x: hidden; 
    scrollbar-width: thin;
    margin-bottom: 10px;
}




.new_project_chat_body .input-group{
    min-height:30px;
    max-height: 30px;
}







.system_message{
    background: #0D0F12;
    border-radius: 8px;
    padding: 25px 10px;
    margin-right:35px;
    margin-top:10px;
}

.user_message{
    background: #FFFFFF;
    border-radius: 8px;
    margin-top: 15px;
    width: fit-content;
    display: flex;
    margin-left: auto;
    margin-right:10px;
    /* align-items:center; */
}

.user_message p{
    color:#121212;
    font-size: 0.9rem;
    padding:15px 25px;
    display: inline-block;
    margin: 0px;
}


.system_message p{
    color:#B7BFC7;
    font-size:0.8rem;
    margin:0
}


.new_project_container .input-group textarea{
    background: #121212 !important;
    color: #FFFFFF !important;
    border:none;
    resize: none;
    overflow: auto;
    outline: none !important;
}


.new_project_container .input-group textarea:focus {
    outline: none; 
    border:none;
    box-shadow: none;
}

.new_project_container .input-group{
    display: flex;
    gap:20px;
}


.new_project_container .input-group,
.new_project_container .input-group span{
    border:none;
}

.new_project_container .input-group span{
    display: flex;
    gap:13px;
    padding: 20px 0px;
}

.new_project_container .input-group span div{
    width: 48px;
    height: 39px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    cursor: pointer;
}

.new_project_container .input-group span div:nth-of-type(1){
    background: #FF8C27;
}

.new_project_container .input-group span div:nth-of-type(2),
.new_project_container .input-group span div:nth-of-type(3){
    background: #121212;
    border: 1px solid #4C4C4C;
}

.new_project_container .input-group span img{
    width:50%;
    cursor:pointer;
}