/* General Reset */
/* * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
} */

/* p, h1,h3,h4 {
  margin: 0;
} */

.header {
background: #1E1E1E;
padding: 40px 0 12px 15px;
display: flex;
gap: 42px;
}

.main {
 padding: 20px;
 background: #121212;
}

.top-tab-btn {
  padding: 9px 14px;
  border-radius: 7px;
    font-weight: 500;
    font-size: 14px;
    line-height: 13.66px;
    color: #000;
    background: #fff;
    margin-right: 10px;
    border: none;
    height: 32px;
}
.bg-orange {
  background: #FF8C27;
}
/* Page Title */
.page-title {
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0%;
  color: #fff;
}


/* Sub Tabs (Upcoming / Past Meetings / Meeting Notes) + Schedule button */
.sub-tabs {
  display: flex;
  justify-content: space-between;
}

.btn-nav {
  background: #27272A;
  border: 0.5px solid #27272A;
  padding: 10px 17px;
  border-radius: 10px;
}

.sub-tab-btn {
  background-color: transparent;
  color: #A1A1A1;
  border: none;
  padding: 6px 30px;
  cursor: pointer;
    font-weight: 700;
    font-size: 12px;
    line-height: 18px;
    border-radius: 5px;
}

.sub-tab-btn.active {
  color: #fff;
background: #09090B;
}

/* Meetings List */
.meetings-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

/* Meeting Card */
.meeting-card {
  background: linear-gradient(0deg, #1E1E1E, #1E1E1E),
      linear-gradient(0deg, #1E1E1E, #1E1E1E);
      border: 1px solid #A1A1A1;
      padding: 22px;
      border-radius: 10px;
  margin-top: 15px;
}

.meeting-card .content {
        justify-content: space-between;
          display: flex;
}

.meeting-info {
  display: flex;
  gap: 15px;
}
.meeting-info .img{
  background: #27272A;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
    padding: 10px 5px;
  border-radius: 10px;
}

.meeting-title {
    font-weight: 500;
    font-size: 15px;
    line-height: 22.5px;
    color: #fff;
    margin-bottom: 5px;
}

.meeting-date {
 color: #C4C4C4;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
}

/* Additional meta info (description, duration, participants) */
.meeting-meta {
  display: flex;
  gap: 40px;
  flex-wrap: wrap;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  color: #A1A1A1;
  margin-top: 18px;
}

.notes {
    font-weight: 500;
      font-size: 13px;
      line-height: 15px;
      color: #A1A1A1;
      margin: 18px 0;
}

/* People / Avatars */
.meeting-people {
  margin-top: 14px;
  display: flex;
  align-items: center;
}

.avatar-group {
  display: flex;
  align-items: center;
  margin-right: 0.5rem;
  border: none;
}

.avatar {
  width: 32px;
  height: 32px;
  background-color: #555;
  border-radius: 50%;
  border: 2px solid #1e1e1e;
  margin-left: -20px; /* Overlap effect */
  border: none;
}

/* For the first avatar, remove the negative margin (optional) */
.avatar:first-child {
  margin-left: 0;
}

.people-note {
    font-weight: 400;
    font-size: 13px;
    line-height: 13.66px;
      color: #A1A1A1;
}

/* Meeting Notes Text */
.meeting-notes {
  margin-top: 0.5rem;
  color: #bbb;
  font-size: 0.9rem;
}

/* Responsive Design */
@media (max-width: 768px) {
  .meeting-meta {
    flex-direction: column;
    gap: 0.25rem;
  }

  .meeting-info {
    flex-direction: column;
    align-items: flex-start;
    gap: 0.25rem;
  }

  .sub-tabs {
    flex-wrap: wrap;
    gap: 0.5rem;
  }
}