/*** Dashboard Header Styling ***/

.user-topnav {
    padding: 0 1px !important;
    margin: 0 !important;
    width: 100% !important;
    height: 75px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 1000; /* Ensure it's above other elements */
    position: relative;
    border-bottom: 1px solid ;
}

.user-topnav .container-fluid{
    padding: 0 !important;
    margin: 0 !important;
    width: 100% !important;
}

.user-topnav .navbar-brand img {
    max-width: 130px;
    height: auto;
}

.dashboard-text {
    font-size: 0.9rem;
    color: #FFFFFF;
    font-weight: 200;
}


.search-box {
    flex-grow: 1;
    max-width: 400px;
    padding:0px !important;
    margin: 0px !important;
}


/* .search-box .input-group{
    
} */

.search-box .input-group span{
    padding: 8px 3px 8px 0px;
    border:none;
    background-color: #121212;
    
}

.search-box .input-group-text {
    background-color: #121212;
    border: none;
    display: flex;
    justify-content: center;
}

.search-box .input-group-text img {
    width: 50%;
}

.search-box input {
    background-color: #121212 !important;
    border: none;
    border-left: none; 
    color: #fff !important;
    padding: 0px !important;
    outline: none;
    box-shadow: none;
    font-size: 0.8rem;
}

.search-box input[type="text"]:focus {
    outline: none;
    border: none;
    box-shadow: none;
}

.back_icon{
    width: auto;
    height:18px;
}


.notification_icon {
    background-color: #121212;
    padding: 5px 0px !important;
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    margin-right: 1.5rem !important;
    cursor: pointer;
}

.notification_icon img {
    width: 55%;
    cursor: pointer;
}


.user-dropdown .user_dropdown_menu{
    background-color:#1E1E1E;
    border: 1px solid #A1A1A1;
    border-radius: 8px;
}


.user-dropdown{
    margin-right: 20px !important;
}

/* .navbarDropdown i{
    font-size: 0.5rem !important;
} */



.user-dropdown .dropdown-item {
    font-size: 0.75rem;
    font-weight: 300;
    color: #FFFFFF;
}

.user-dropdown .dropdown-item:hover{
    color: #121212;
}


.user-dropdown .dropdown{
    display: flex;
    align-items: center;
    justify-content: start

}


.dashboard_user_profile_pic{
    width:40px !important;
    height:40px !important;
    border-radius: 50% !important;
    object-fit: cover;
    object-position: top;
}



