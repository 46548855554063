/*** Dashboard Nav Styling ***/

#dashboard_nav {
    display: flex;
    flex-direction: column;
    height: 100dvh;
    border-right: 1px solid #30363D;
    padding-left: 5px;
    justify-content: start;
    align-items: start;
    width: var(--sidenav-width);
    flex: 0 0 var(--sidenav-width);
    transition: width 0.3s ease-in-out;
    overflow-y: auto;
}




.dashboard_nav_logo_container {
    position:relative;
    width: 100%;
    display: flex;
    align-items: center;
    height: 45px;
    justify-content: start;
    padding: 0px;
    margin-top: 15px;
}

.dashboard_nav_logo_container .navbar-brand img {
    height: 2.1rem !important; /* Ensure the logo appears larger */
    max-height: none;
    width: auto; /* Maintain aspect ratio */
    margin-left: 12px; /* Optional spacing */
}


.sb-sidenav {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    width: 100%;
}

.sb-sidenav .sb-sidenav-menu {
    flex-grow: 1;
}
  

.current_project_nav {
    display: flex;
    flex-direction: column;
    justify-content: space-between !important;
    padding: 3px 17px;
    height: calc(100vh - 160px);
    overflow-y: auto;
    scrollbar-width: thin; /* For Firefox */
    scrollbar-color: transparent transparent;
}

/* WebKit */
::-webkit-scrollbar {
    width: 0;
    background-color: transparent;
}



.current_project_title{
    color:#FFFFFF;
    font-size: 1rem;
    margin-bottom: 30px;
}


/* .current_projects_box{
    height:calc(100dvh - 200px);
    overflow-y: auto;

} */


.current_project_link{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    cursor: pointer;
}

.current_project_link div{
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.current_project_link .project_title_link{
    text-decoration: none;
}

.current_project_link h4{
    color:#FFFFFF;
    font-size: 0.8rem;
}

.current_project_link p{
    color:#FFFFFF;
    font-size: 0.5rem;
    cursor: pointer;
}

.current_project_link span{
    border-radius: 15px;
    font-style: normal;
    font-size: 0.6rem;
    padding: 7px 15px;
}

.current_project_link .pending_review{
    color: #EA6045;
    background: rgba(234, 96, 69, 0.1);
}

.current_project_link .in_progress{
    color: #FF8C27;
    background: rgba(255, 140, 39, 0.1);
}


.current_project_link .completed{
    color: #02B717;
    background: rgba(2, 183, 23, 0.1);
}

.current_project_link .collaborators div {
    position: relative;
    display: inline-flex; 
    margin: 0;
    padding: 0;
}

.current_project_link .collaborators span{
    color:#B7BFC7;
    font-size: 0.7rem;
    padding:0;
    border-radius: 0;
}




.current_project_link .collaborators img {
    position: relative;
    width: 25px; 
    height: 25px; 
    border-radius: 50%;
    margin-left: -18px; 
    z-index: 1; 
}

.current_project_link .collaborators img:nth-of-type(1) {
    z-index: 1;
    margin-left: 0; 
}

.current_project_link .collaborators img:nth-of-type(2) {
    z-index: 2;
}

.current_project_link .collaborators img:nth-of-type(3) {
    z-index: 3;
}

.current_project_link .collaborators img:nth-of-type(4) {
    z-index: 4;
}

.current_project_nav hr{
    border: 1px solid #30363D;
    width: 100%;
    margin: 1.2rem auto;
    display: flex;
    align-self: center;
}


.sb-sidenav-menu .d-grid{
    padding:20px;
}

.sb-sidenav-menu .d-grid button{
    font-size: 0.9rem;
}
