.Btn27272a {
  padding: 12px 9px 12px 9px;
  background-color: #27272a;
  border-radius: 7px;
  border: none;
  color: white;
  font-size: 10px;
  font-weight: 500;
}
.Btn000000 {
  padding: 12px 9px 12px 9px;
  background-color: #000000;
  border-radius: 7px;
  border: none;
  color: white;
  font-size: 10px;
  font-weight: 500;
  width: 104px;
}
